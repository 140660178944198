export const objectToQueryString = (obj = {}) => {
  var queryString = [];
  for (var key in obj) {
    queryString.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
    );
  }
  return queryString.join("&");
};

export const queryStringToObject = (queryString = "") => {
  var pairs = queryString.split("&");
  var obj = {};
  pairs.forEach((pair) => {
    var keyValue = pair.split("=");
    if (keyValue[0]) {
      obj[decodeURIComponent(keyValue[0])] = decodeURIComponent(
        keyValue[1] || ""
      );
    }
  });
  return obj;
};

export const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const formatDatee = (dateString) => {
  const date = new Date(dateString);

  // Get date components
  const day = String(date.getDate()).padStart(2, "0"); // Day
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month (0-indexed, so +1)
  const year = date.getFullYear(); // Year
  const hours = String(date.getHours()).padStart(2, "0"); // Hours
  const minutes = String(date.getMinutes()).padStart(2, "0"); // Minutes

  // Return formatted date: "MM/DD/YYYY • HH:mm"
  return `${month}/${day}/${year} • ${hours}:${minutes}`;
};

export const removeSpecialCharacters = (str) => {
  return str.replace(/[^a-zA-Z0-9\s]/g, "");
};
export const removeNumberSpecialCharacters = (str) => {
  return str.replace(/[^0-9]/g, "");
};

export const capitalizeFirstLetter = (input) => {
  if (!input) return "";
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};
