import { Link } from "react-router-dom";
import ArrowRight from "../../assets/svg/ArrowRight";
import HeaderBar from "./HeaderBar";

const Breadcrumb = ({ links = [], active = "", nas_header_bar = true }) => {
  return (
    <>
      <div className="overflow-x-auto">
        <div className="border-b-[1px] border-b-greyMercury flex w-full">
          <div className="px-6 py-2">
            <div className="flex flex-wrap items-center gap-2 ">
              {links.map((item, index) => (
                <div
                  className="flex justify-center items-center gap-2"
                  key={index}
                >
                  <div
                    key={index + "edsxrads"}
                    className="text-dark text-xs font-medium"
                  >
                    <Link to={item?.link ?? "/"}>{item?.title ?? ""}</Link>
                  </div>
                  <div>
                    <ArrowRight stroke={"#5D0D56"} width={3} height={6} />
                  </div>
                </div>
              ))}
              <div className="text-blueWaterloo text-xs font-normal">
                {active}
              </div>
            </div>
          </div>
        </div>
      </div>
      {nas_header_bar ? <HeaderBar label={active} /> : null}
    </>
  );
};
export default Breadcrumb;
