import { t } from "i18next";
import React, { useState } from "react";
import Select, { components } from "react-select";

const Menu = (props) => {
  return (
    <>
      <components.Menu {...props}>
        <div>
          <div>{props.children}</div>
          {props?.selectProps?.options?.length ===
          props?.selectProps?.stateOptions?.length ? null : (
            <button
              className={"p-3 hover:bg-greylight hover:text-blue flex w-full"}
              onClick={props.selectProps.changeOptionsData}
            >
              {t("custom_select.change_data")}
            </button>
          )}
        </div>
      </components.Menu>
    </>
  );
};

const CustomSelect = () => {
  const [stateOptions, setStateOptions] = useState([
    { id: "1", value: "Item 1", label: "Item 1" },
    { id: "2", value: "Item 2", label: "Item 2" },
    { id: "3", value: "Item 3", label: "Item 3" },
    { id: "4", value: "Item 4", label: "Item 4" },
    { id: "5", value: "Item 5", label: "Item 5" },
    { id: "6", value: "Item 6", label: "Item 6" },
    { id: "7", value: "Item 7", label: "Item 7" },
    { id: "8", value: "Item 8", label: "Item 8" },
    { id: "9", value: "Item 9", label: "Item 9" },
    { id: "10", value: "Item 10", label: "Item 10" },
    { id: "11", value: "Item 11", label: "Item 11" },
    { id: "12", value: "Item 12", label: "Item 12" },
    { id: "13", value: "Item 13", label: "Item 13" },
    { id: "14", value: "Item 14", label: "Item 14" },
    { id: "15", value: "Item 15", label: "Item 15" },
  ]);

  const [array, setArray] = useState(stateOptions.slice(0, 9)); // 1chi 8ta element

  const changeOptionsData = () => {
    const remainingElements = stateOptions.slice(9); // 1chi 8 ta element dan keyin barcha elementlarni olish
    setArray((prevArray) => [...prevArray, ...remainingElements]); // Massivga olingan elementlarni qo'shish
  };

  return (
    <div>
      <Select
        options={array}
        components={{ Menu }}
        changeOptionsData={changeOptionsData}
        stateOptions={stateOptions}
      />
    </div>
  );
};
export default CustomSelect;
