import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AUTH_URL } from "../../utils/api";
import Axios from "../../utils/httpClient";
import { getToken, setToken } from "../../utils/tokenStorge";
import Header from "./Header";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = getToken();
  const { user } = useSelector((s) => s);

  useEffect(() => {
    const expires_date = localStorage.getItem("expires_date");
    // const expires_in = localStorage.getItem("expires_in");
    if (expires_date) {
      const currentdate = new Date();
      const lastdate = new Date(
        // currentdate.getTime() + (parseInt(expires_in) - 120) * 1000
        currentdate.getTime() + 180 * 1000
      );
      const expiresdate = new Date(expires_date);
      if (lastdate >= expiresdate) {
        getRefrasheToken();
      }
    }
    const intervalId = setInterval(() => {
      const expires_date = localStorage.getItem("expires_date");
      // const expires_in = localStorage.getItem("expires_in");
      if (expires_date) {
        const currentdate = new Date();
        const lastdate = new Date(
          // currentdate.getTime() + (parseInt(expires_in) - 120) * 1000
          currentdate.getTime() + 180 * 1000
        );
        const expiresdate = new Date(expires_date);
        // console.log(lastdate, expiresdate);
        if (lastdate >= expiresdate) {
          getRefrasheToken();
        }
      }
    }, 60000);
    return () => clearInterval(intervalId);
  }, [location]);

  useEffect(() => {
    if (!user?.id) {
      getUser();
    }
  }, []);

  const getUser = () => {
    Axios(AUTH_URL, token)
      .post(`api/v1/user`)
      .then((r) => {
        dispatch({ type: "SET_USER", payload: r?.data?.data ?? {} });
        dispatch({ type: "SET_ROLE", payload: r?.data?.data?.roles ?? {} });
      })
      .catch((e) => {})
      .finally(() => {});
  };
  const getRefrasheToken = () => {
    const refresh_token = localStorage.getItem("refresh_token");
    Axios(AUTH_URL)
      .post(`api/v1/refresh`, { refresh_token: refresh_token })
      .then((r) => {
        const body = r?.data ?? {};
        if (body?.access_token) {
          setToken(body?.access_token);
          const currentdate = new Date();
          const expiresdate = new Date(
            currentdate.getTime() + parseInt(body?.expires_in) * 1000
          );
          localStorage.setItem("refresh_token", body?.refresh_token);
          localStorage.setItem("expires_in", body?.expires_in);
          localStorage.setItem("expires_date", expiresdate);
        }
      })
      .catch((e) => {})
      .finally(() => {});
  };

  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default Layout;
