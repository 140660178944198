import { Checkbox } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { EditIcon, PlusIcon } from "../../assets/svg";
import ListSection from "../../sections/listSections/ListSection";
import { MAIN_URL } from "../../utils/api";
import { default_per_page, status_bg } from "../../utils/constants";
import {
  Breadcrumb,
  ButtonMain,
  RoundFirstLetter,
  SlidingTabs,
  Status,
  Td,
  Th,
} from "../ui_components";
import Axios from "../../utils/httpClient";
import { get } from "lodash";

const Patients = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkedIds, setCheckedIds] = useState([]);
  const [countStatus, setCountStatus] = useState({});
  useEffect(() => {
    getCountStatus();
  }, []);
  const getCountStatus = () => {
    Axios(MAIN_URL)
      .get(`api/v1/patient-count`)
      .then((r) => {
        setCountStatus(r.data);
      })
      .catch((e) => {
        setCountStatus({});
      })
      .finally(() => {});
  };

  return (
    <>
      <Breadcrumb
        links={
          [
            /*{ link: "/", title: t("patients.dashboard") }*/
          ]
        }
        active={t("header.patients")}
        nas_header_bar={false}
      />
      <div className="py-4 px-6 flex flex-col gap-4">
        <div className="flex justify-between items-center flex-wrap gap-4">
          <h1 className="text-xl font-semibold text-dark">
            {t("patients.all_patients")}
          </h1>
          <div className="flex gap-2.5 justify-between items-center flex-wrap">
            {/* <ButtonMain
              text={t("patients.filter")}
              type=""
              type_color="t_bluelight"
              lefticon={<MenuIcon stroke="#3164F7" />}
            /> */}
            <Link to={"/patients/new/passport-data"}>
              <ButtonMain
                text={t("patients.add_patient")}
                type=""
                type_color="t_blue"
                lefticon={<PlusIcon stroke="#fff" />}
              />
            </Link>
          </div>
        </div>
        <div className="pb-2.5">
          <SlidingTabs
            tabs={[
              {
                id: 2,
                name: t("save_warning.active"),
                count: get(countStatus, "active", ""),
                clickEvent: () => {
                  navigate(
                    `/patients?page=1&per_page=${default_per_page}&status=2`
                  );
                },
              },
              {
                id: 1,
                name: t("save_warning.drafts"),
                count: get(countStatus, "drafts", ""),
                clickEvent: () => {
                  navigate(
                    `/patients?page=1&per_page=${default_per_page}&status=1`
                  );
                },
              },
            ]}
          />
        </div>
      </div>
      <ListSection
        api_query={`api/v1/patient`}
        api_url={MAIN_URL}
        ThBlock={({ list_item }) => (
          <>
            <tr className="text-left bg-grey">
              <Th className="!min-w-[40px] font-medium">
                <div className="flex items-center gap-4">
                  <Checkbox
                    id="agree"
                    color={"#3164F7"}
                    style={{
                      backgroundImage: "url(/images/minus.svg)",
                      padding: "1px",
                    }}
                    checked={
                      checkedIds.length ===
                      list_item.map((list_item) => list_item.id).length
                    }
                    onChange={() => {
                      const listCheckedIds = list_item.map(
                        (listId) => listId.id
                      );
                      if (checkedIds.length === listCheckedIds.length) {
                        setCheckedIds([]);
                      } else {
                        setCheckedIds(listCheckedIds);
                      }
                    }}
                  />
                  {/* {t("patients.table.id")} */}
                  {t("patients.table.id_p")}
                </div>
              </Th>
              {/* <Th className="font-medium">{t("patients.table.id_p")}</Th> */}
              <Th className="font-medium">{t("patients.table.fio")}</Th>
              <Th className="font-medium">{t("patients.table.birht_date")}</Th>
              <Th className="font-medium">{t("patients.table.pinfl")}</Th>
              {/* <Th className="font-medium">{t("patients.table.taken")}</Th> */}
              {/* <Th className="font-medium !min-w-[260px] ">
                {t("patients.table.filllial")}
              </Th> */}
              <Th className="font-medium">{t("patients.table.status")}</Th>
              <Th className="table_col_fixed">8888</Th>
            </tr>
            <tr className="text-left bg-grey">
              <Th className="!min-w-[40px] font-medium">
                <div className="flex items-center gap-4">
                  <Checkbox
                    id="agree"
                    color={"#3164F7"}
                    style={{
                      backgroundImage: "url(/images/minus.svg)",
                      padding: "1px",
                    }}
                    checked={
                      checkedIds.length ===
                      list_item.map((list_item) => list_item.id).length
                    }
                    onChange={() => {
                      const listCheckedIds = list_item.map(
                        (listId) => listId.id
                      );
                      if (checkedIds.length === listCheckedIds.length) {
                        setCheckedIds([]);
                      } else {
                        setCheckedIds(listCheckedIds);
                      }
                    }}
                  />
                  {/* {t("patients.table.id")} */}
                  {t("patients.table.id_p")}
                </div>
              </Th>
              {/* <Th className="font-medium">{t("patients.table.id_p")}</Th> */}
              <Th className="font-medium">{t("patients.table.fio")}</Th>
              <Th className="font-medium">{t("patients.table.birht_date")}</Th>
              <Th className="font-medium">{t("patients.table.pinfl")}</Th>
              {/* <Th className="font-medium">{t("patients.table.taken")}</Th> */}
              {/* <Th className="font-medium !min-w-[260px] ">
              {t("patients.table.filllial")}
            </Th> */}
              <Th className="font-medium">{t("patients.table.status")}</Th>
              <Th className="table_col_fixed">8888</Th>
            </tr>
          </>
        )}
        TdBlock={({ td_item, td_index }) => {
          return (
            <tr className="text-left bg-white" key={td_index}>
              <Td>
                <div className="flex gap-4 items-center">
                  <Checkbox
                    id={td_item.id}
                    color={"#3164F7"}
                    checked={checkedIds.includes(td_item?.id)}
                    onChange={(event) => {
                      event.stopPropagation();
                      const index = checkedIds.indexOf(td_item?.id);

                      if (index > -1) {
                        setCheckedIds([
                          ...checkedIds.slice(0, index),
                          ...checkedIds.slice(index + 1),
                        ]);
                      } else {
                        setCheckedIds([...checkedIds, td_item?.id]);
                      }
                    }}
                  />
                  {td_item?.id}
                </div>
              </Td>
              {/* <Td>{td_item?.outpatient_card}</Td> */}
              <Td>
                <div className="flex items-center gap-2">
                  <RoundFirstLetter letter={td_item?.first_name.charAt(0)} />
                  <div className=" whitespace-nowrap">
                    {td_item?.first_name} {td_item?.last_name}
                  </div>
                </div>
              </Td>
              <Td>{td_item?.date_birth?.split("-").reverse().join("/")}</Td>
              <Td>{td_item?.nnuzb}</Td>
              {/* <Td>
                {td_item?.date_registration?.split("-").reverse().join("/")}
              </Td> */}
              {/* <Td>{td_item?.medical_institution?.name}</Td> */}
              <Td>
                <Status
                  text={td_item?.current_status?.name}
                  type_status={
                    status_bg.filter(
                      (s) => s?.id === td_item?.current_status?.id
                    )[0]?.bg_color
                  }
                />
              </Td>
              <Td className="table_col_fixed">
                <Link to={`/patients/${td_item?.id}/passport-data`}>
                  <ButtonMain
                    text={t("utils.edit")}
                    type="button"
                    type_color="t_bluelight"
                    lefticon={<EditIcon stroke="#3164F7" />}
                  />
                </Link>
              </Td>
            </tr>
          );
        }}
        defaultFilter={{ status: 2 }}
      />
    </>
  );
};

export default Patients;
