import React, { Suspense, lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { CallBack, Login } from "../components/auth_pages";
import OtchotTable from "../components/main_pages/OtchotTable";
// import Dispensarizations from "../components/patient_pages/Dispensarizations";
import Callback2 from "../components/auth_pages/CallBack2";
import Login2 from "../components/auth_pages/Login2";
import Layout from "../sections/layoutSections/Layout";

const Home = lazy(() => import("../components/main_pages/Home"));
const Patients = lazy(() => import("../components/patient_pages/Patients"));
const Lists = lazy(() => import("../components/lists_pages/Lists"));
const Logss = lazy(() => import("../components/logss/Logss"));
const LogsTableAll = lazy(() => import("../components/logss/LogsTableAll"));
const ListsCreate = lazy(() => import("../components/lists_pages/ListsCreate"));
const ListResult = lazy(() => import("../components/lists_pages/ListResult"));
const Directories = lazy(() =>
  import("../components/directories_pages/Directories")
);
const DirectoriesDetails = lazy(() =>
  import("../components/directories_pages/DirectoriesDetails")
);
const Report1 = lazy(() => import("../components/reports_pages/Report1"));
const Report2 = lazy(() => import("../components/reports_pages/Report2"));
const Report3 = lazy(() => import("../components/reports_pages/Report3"));
const Report4 = lazy(() => import("../components/reports_pages/Report4"));
const Report5 = lazy(() => import("../components/reports_pages/Report5"));
const Report6 = lazy(() => import("../components/reports_pages/Report6"));
const Report7 = lazy(() => import("../components/reports_pages/Report7"));
const Report8 = lazy(() => import("../components/reports_pages/Report8"));
const Report9 = lazy(() => import("../components/reports_pages/Report9"));
const Report10 = lazy(() => import("../components/reports_pages/Report10"));
// const ClinicalGroup = lazy(() =>
//   import("../components/patient_pages/IHC_pages/ClinicalGroup")
// );
const PatientsCreate = lazy(() =>
  import("../components/patient_pages/PatientsCreate")
);
// const DispensarizationCreate = lazy(() =>
//   import("../components/patient_pages/DispensarizationCreate")
// );
const DiagnosCreate = lazy(() =>
  import("../components/patient_pages/DiagnosCreate")
);
const Treatment = lazy(() => import("../components/patient_pages/Treatment"));
const TreatmentCreate = lazy(() =>
  import("../components/patient_pages/TreatmentCreate")
);
const Profile = lazy(() => import("../components/profile_pages/Profile"));
const NotFount = lazy(() => import("../components/main_pages/NotFount"));
const SurgeryTreatmentsCreate = lazy(() =>
  import(
    "../components/patient_pages/treatment_type_pages/SurgeryTreatmentsCreate"
  )
);
const SurgeryTreatments = lazy(() =>
  import("../components/patient_pages/treatment_type_pages/SurgeryTreatments")
);
const ChemotherapyTreatments = lazy(() =>
  import(
    "../components/patient_pages/treatment_type_pages/ChemotherapyTreatments"
  )
);
const ChemotherapyTreatmentsCreate = lazy(() =>
  import(
    "../components/patient_pages/treatment_type_pages/ChemotherapyTreatmentsCreate"
  )
);
const RadiationTherapy = lazy(() =>
  import("../components/patient_pages/treatment_type_pages/RadiationTherapy")
);
const RadiationTherapyCreate = lazy(() =>
  import(
    "../components/patient_pages/treatment_type_pages/RadiationTherapyCreate"
  )
);
const RadiomodifiersTherapyCreate = lazy(() =>
  import(
    "../components/patient_pages/treatment_type_pages/RadiomodifiersTherapyCreate"
  )
);
const RadiomodifiersTherapy = lazy(() =>
  import(
    "../components/patient_pages/treatment_type_pages/RadiomodifiersTherapy"
  )
);
const OtherRadiationTherapy = lazy(() =>
  import(
    "../components/patient_pages/treatment_type_pages/OtherRadiationTherapy"
  )
);
const OtherRadiationTherapyCreate = lazy(() =>
  import(
    "../components/patient_pages/treatment_type_pages/OtherRadiationTherapyCreate"
  )
);
const Ui = lazy(() => import("../components/Ui"));
const ListUi = lazy(() => import("../components/ListUi"));
const IHCSpecific = lazy(() =>
  import("../components/patient_pages/IHC_pages/IHCSpecific")
);
const IHCNonSpecific = lazy(() =>
  import("../components/patient_pages/IHC_pages/IHCNonSpecific")
);
const IHCMolecularGenetic = lazy(() =>
  import("../components/patient_pages/IHC_pages/IHCMolecularGenetic")
);
const RelapsesMetastases = lazy(() =>
  import("../components/patient_pages/IHC_pages/RelapsesMetastases")
);

const Test1 = lazy(() => import("../components/test/Test1"));
const Test2 = lazy(() => import("../components/test/Test2"));
// const InfoAboutNeglect = lazy(() =>
//   import("../components/patient_pages/IHC_pages/InfoAboutNeglect")
// );
const Routers = () => {
  return (
    <Routes>
      <Route path="/auth/callback" element={<CallBack />} />
      <Route path="/auth/callback2" element={<Callback2 />} />
      <Route path="/auth/login" element={<Login2 />} />
      <Route
        path="/test1"
        element={
          <Suspense>
            <Test1 />
          </Suspense>
        }
      />
      <Route
        path="/test2"
        element={
          <Suspense>
            <Test2 />
          </Suspense>
        }
      />
      <Route
        element={
          <Layout>
            <Outlet />
          </Layout>
        }
      >
        <Route path="/">
          <Route
            index
            element={
              <Suspense>
                <Home />
              </Suspense>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/patients">
            <Route
              index
              element={
                <Suspense>
                  <Patients />
                </Suspense>
              }
            />
            <Route
              path="new/passport-data"
              element={
                <Suspense>
                  <PatientsCreate />
                </Suspense>
              }
            />
            <Route path=":patient_id">
              <Route
                path="passport-data"
                element={
                  <Suspense>
                    <PatientsCreate />
                  </Suspense>
                }
              />
              <Route
                path="diagnosis/new"
                element={
                  <Suspense>
                    <DiagnosCreate />
                  </Suspense>
                }
              />
              <Route path="diagnosis/:diagnosis_id">
                <Route
                  index
                  element={
                    <Suspense>
                      <DiagnosCreate />
                    </Suspense>
                  }
                />
                <Route path="treatments">
                  <Route
                    index
                    element={
                      <Suspense>
                        <Treatment />
                      </Suspense>
                    }
                  />
                  <Route
                    path="new"
                    element={
                      <Suspense>
                        <TreatmentCreate />
                      </Suspense>
                    }
                  />
                  <Route path=":treatment_id">
                    <Route
                      index
                      element={
                        <Suspense>
                          <TreatmentCreate />
                        </Suspense>
                      }
                    />
                    <Route path="surgery-treatments">
                      <Route
                        index
                        element={
                          <Suspense>
                            <SurgeryTreatments />
                          </Suspense>
                        }
                      />
                      <Route
                        path="new"
                        element={
                          <Suspense>
                            <SurgeryTreatmentsCreate />
                          </Suspense>
                        }
                      />
                      <Route
                        path=":treatment_type_id"
                        element={
                          <Suspense>
                            <SurgeryTreatmentsCreate />
                          </Suspense>
                        }
                      />
                    </Route>
                    <Route path="chemotherapy-treatments">
                      <Route
                        index
                        element={
                          <Suspense>
                            <ChemotherapyTreatments />
                          </Suspense>
                        }
                      />
                      <Route
                        path="new"
                        element={
                          <Suspense>
                            <ChemotherapyTreatmentsCreate />
                          </Suspense>
                        }
                      />
                      <Route
                        path=":treatment_type_id"
                        element={
                          <Suspense>
                            <ChemotherapyTreatmentsCreate />
                          </Suspense>
                        }
                      />
                    </Route>
                    <Route path="radiation-therapy">
                      <Route
                        index
                        element={
                          <Suspense>
                            <RadiationTherapy />
                          </Suspense>
                        }
                      />
                      <Route
                        path="new"
                        element={
                          <Suspense>
                            <RadiationTherapyCreate />
                          </Suspense>
                        }
                      />
                      <Route
                        path=":treatment_type_id"
                        element={
                          <Suspense>
                            <RadiationTherapyCreate />
                          </Suspense>
                        }
                      />
                    </Route>
                    <Route path="radiomodifiers-therapy">
                      <Route
                        index
                        element={
                          <Suspense>
                            <RadiomodifiersTherapy />
                          </Suspense>
                        }
                      />
                      <Route
                        path="new"
                        element={
                          <Suspense>
                            <RadiomodifiersTherapyCreate />
                          </Suspense>
                        }
                      />
                      <Route
                        path=":treatment_type_id"
                        element={
                          <Suspense>
                            <RadiomodifiersTherapyCreate />
                          </Suspense>
                        }
                      />
                    </Route>
                    <Route path="other-radiation-therapy">
                      <Route
                        index
                        element={
                          <Suspense>
                            <OtherRadiationTherapy />
                          </Suspense>
                        }
                      />
                      <Route
                        path="new"
                        element={
                          <Suspense>
                            <OtherRadiationTherapyCreate />
                          </Suspense>
                        }
                      />
                      <Route
                        path=":treatment_type_id"
                        element={
                          <Suspense>
                            <OtherRadiationTherapyCreate />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Route>
                </Route>

                <Route
                  path="ihc-specific"
                  element={
                    <Suspense>
                      <IHCSpecific />
                    </Suspense>
                  }
                />
                <Route
                  path="ihc-nonspecific"
                  element={
                    <Suspense>
                      <IHCNonSpecific />
                    </Suspense>
                  }
                />
                <Route
                  path="ihc-molecutlar-genetic"
                  element={
                    <Suspense>
                      <IHCMolecularGenetic />
                    </Suspense>
                  }
                />
                <Route
                  path="relapse-metastase"
                  element={
                    <Suspense>
                      <RelapsesMetastases />
                    </Suspense>
                  }
                />
                {/* <Route
                  path="info-about-neglect"
                  element={
                    <Suspense>
                      <InfoAboutNeglect />
                    </Suspense>
                  }
                /> */}
              </Route>
              {/* <Route
                path="relapse-metastase"
                element={
                  <Suspense>
                    <RelapsesMetastases />
                  </Suspense>
                }
              /> */}
              {/* <Route
                path="clinical-group"
                element={
                  <Suspense>
                    <ClinicalGroup />
                  </Suspense>
                }
              /> */}
              {/* <Route
                path="dispensarization"
                element={
                  <Suspense>
                    <DispensarizationCreate />
                  </Suspense>
                }
              /> */}
              {/* <Route path="dispensarizations">
                <Route
                  index
                  element={
                    <Suspense>
                      <Dispensarizations />
                    </Suspense>
                  }
                />
                <Route
                  path="new"
                  element={
                    <Suspense>
                      <DispensarizationCreate />
                    </Suspense>
                  }
                />
                <Route path=":dispenser_id">
                  <Route
                    index
                    element={
                      <Suspense>
                        <DispensarizationCreate />
                      </Suspense>
                    }
                  />
                </Route>
              </Route> */}
            </Route>
          </Route>

          <Route path="/lists">
            <Route
              index
              element={
                <Suspense>
                  <Lists />
                </Suspense>
              }
            />
            <Route
              path="new"
              element={
                <Suspense>
                  <ListsCreate />
                </Suspense>
              }
            />
            <Route path=":list_id">
              <Route
                index
                element={
                  <Suspense>
                    <ListsCreate />
                  </Suspense>
                }
              />
              <Route
                path="result"
                element={
                  <Suspense>
                    <ListResult />
                  </Suspense>
                }
              />
            </Route>
          </Route>

          <Route path="/directories">
            <Route
              index
              element={
                <Suspense>
                  <Directories />
                </Suspense>
              }
            />
            <Route path=":table">
              <Route
                index
                element={
                  <Suspense>
                    <Directories>
                      <DirectoriesDetails />
                    </Directories>
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route path="/logs2">
            <Route
              index
              element={
                <Suspense>
                  <Logss />
                </Suspense>
              }
            />
          </Route>
          <Route path="/logs">
            <Route
              index
              element={
                <Suspense>
                  <LogsTableAll />
                </Suspense>
              }
            />
          </Route>
          <Route path="/reports">
            <Route
              index
              element={
                <Suspense>
                  <Report1 />
                </Suspense>
              }
            />
            <Route
              path="report-2"
              element={
                <Suspense>
                  <Report2 />
                </Suspense>
              }
            />{" "}
            <Route
              path="report-3"
              element={
                <Suspense>
                  <Report3 />
                </Suspense>
              }
            />{" "}
            <Route
              path="report-4"
              element={
                <Suspense>
                  <Report4 />
                </Suspense>
              }
            />
            <Route
              path="report-5"
              element={
                <Suspense>
                  <Report5 />
                </Suspense>
              }
            />
            <Route
              path="report-6"
              element={
                <Suspense>
                  <Report6 />
                </Suspense>
              }
            />
            <Route
              path="report-7"
              element={
                <Suspense>
                  <Report7 />
                </Suspense>
              }
            />
            <Route
              path="report-8"
              element={
                <Suspense>
                  <Report8 />
                </Suspense>
              }
            />
            <Route
              path="report-9"
              element={
                <Suspense>
                  <Report9 />
                </Suspense>
              }
            />
            <Route
              path="report-10"
              element={
                <Suspense>
                  <Report10 />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/profile"
            element={
              <Suspense>
                <Profile />
              </Suspense>
            }
          />

          <Route
            path="/ui"
            element={
              <Suspense>
                <Ui />
              </Suspense>
            }
          />
          <Route
            path="/otchot-table"
            element={
              <Suspense>
                <OtchotTable />
              </Suspense>
            }
          />
          <Route
            path="/list-ui"
            element={
              <Suspense>
                <ListUi />
              </Suspense>
            }
          />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <Suspense>
            <NotFount />
          </Suspense>
        }
      />
    </Routes>
  );
};
export default Routers;
