import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'swiper/css';
// import { default as ToastProvider } from "./components/ui_components/ToastProvider";
import Routers from './routers/Routers';
import WebProvider from './utils/WebProvider';
import createStore from './utils/store';

const App = () => {
	const store = createStore();
	return (
		<Provider store={store}>
			<WebProvider>
				<BrowserRouter>
					<Routers />
				</BrowserRouter>
				{/* <ToastProvider /> */}
			</WebProvider>
		</Provider>
	);
};
export default App;
