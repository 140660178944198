import React from "react";

const SearchingIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || "22"}
      height={props?.height || "20"}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4673 19.528C19.7589 19.8222 20.2338 19.8243 20.528 19.5327C20.8222 19.2411 20.8243 18.7662 20.5327 18.472L19.4673 19.528ZM16.2365 16.2682L19.4673 19.528L20.5327 18.472L17.3019 15.2123L16.2365 16.2682ZM19.35 11.3C19.35 7.40639 16.1936 4.25 12.3 4.25V5.75C15.3652 5.75 17.85 8.23482 17.85 11.3H19.35ZM12.3 4.25C8.40639 4.25 5.25 7.40639 5.25 11.3H6.75C6.75 8.23482 9.23482 5.75 12.3 5.75V4.25ZM5.25 11.3C5.25 15.1936 8.40639 18.35 12.3 18.35V16.85C9.23482 16.85 6.75 14.3652 6.75 11.3H5.25ZM12.3 18.35C16.1936 18.35 19.35 15.1936 19.35 11.3H17.85C17.85 14.3652 15.3652 16.85 12.3 16.85V18.35Z"
        fill="#141B34"
      />
      <path
        d="M3 8C2.51076 7.96288 2.16925 7.87932 1.88886 7.69117C1.67048 7.54463 1.48298 7.35634 1.33706 7.13703C1 6.63045 1 5.92524 1 4.51483C1 3.10442 1 2.39921 1.33706 1.89263C1.48298 1.67332 1.67048 1.48502 1.88886 1.33849C2.39331 1 3.09554 1 4.5 1H17.5C18.9045 1 19.6067 1 20.1111 1.33849C20.3295 1.48502 20.517 1.67332 20.6629 1.89263C21 2.39921 21 3.10442 21 4.51483C21 5.80256 21 6.50243 20.7435 7"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SearchingIcon;
