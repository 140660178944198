import React from "react";

const CloudDownloadIcon = (props) => {
  return (
    <svg
      {...props}
      width={props.width || "20"}
      height={props.height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.565 7.50922C14.5712 7.50919 14.5774 7.50917 14.5837 7.50917C16.6547 7.50917 18.3337 9.19118 18.3337 11.2661C18.3337 13.1998 16.8753 14.7924 15.0003 15M14.565 7.50922C14.5773 7.37172 14.5837 7.23247 14.5837 7.09174C14.5837 4.55579 12.5316 2.5 10.0003 2.5C7.60302 2.5 5.6356 4.34389 5.43401 6.69326M14.565 7.50922C14.4798 8.45632 14.1075 9.3205 13.536 10.0138M5.43401 6.69326C3.32031 6.89477 1.66699 8.67827 1.66699 10.8486C1.66699 12.8681 3.09846 14.5527 5.00033 14.9394M5.43401 6.69326C5.56554 6.68072 5.69885 6.67431 5.83366 6.67431C6.77185 6.67431 7.63761 6.98495 8.33407 7.50917"
        stroke={props?.stroke || "#3164F7"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0003 17.5L10.0003 10.8333M10.0003 17.5C9.4168 17.5 8.3266 15.8381 7.91699 15.4167M10.0003 17.5C10.5838 17.5 11.6741 15.8381 12.0837 15.4167"
        stroke={props?.stroke || "#3164F7"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloudDownloadIcon;
