import React from "react";

const WarningIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || "85"}
      height={props?.height || "84"}
      viewBox="0 0 85 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        width="84"
        height="84"
        rx="42"
        fill={props?.stroke || "#F44444"}
        fillOpacity="0.08"
      />
      <path
        d="M35.8217 39.6829C38.2354 35.412 39.4422 33.2765 41.0983 32.7268C42.0093 32.4244 42.9907 32.4244 43.9017 32.7268C45.5578 33.2765 46.7646 35.412 49.1783 39.6829C51.592 43.9539 52.7988 46.0893 52.4368 47.8293C52.2376 48.7866 51.7469 49.6549 51.035 50.3097C49.741 51.5 47.3274 51.5 42.5 51.5C37.6726 51.5 35.259 51.5 33.965 50.3097C33.2531 49.6549 32.7624 48.7866 32.5632 47.8293C32.2012 46.0893 33.408 43.9539 35.8217 39.6829Z"
        stroke={props?.stroke || "#F44444"}
        strokeWidth="1.5"
      />
      <path
        d="M42.492 46H42.501"
        stroke={props?.stroke || "#F44444"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.5 43L42.5 39"
        stroke={props?.stroke || "#F44444"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarningIcon;
