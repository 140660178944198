// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowUp } from "../../assets/svg";
import { Dropdown } from "flowbite-react";
import { useTranslation } from "react-i18next";

const Pagination = ({
  link = window.location.pathname,
  active = 1,
  total = 0,
  count = 15,
  search_params = "",
}) => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const p = total / count;
  const pc = p > parseInt(p) ? parseInt(p) + 1 : p;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (active !== 1 && (pc < active || active < 1)) {
      navigate(`${link}?page=1&per_page=${count}&${search_params}`);
    }
  }, [active]);

  const handlePageSize = (size) => {
    if (size) {
      navigate(`${link}?page=1&per_page=${size}&${search_params}`);
    }
  };
  const { t } = useTranslation();

  return (
    <div className="py-3 flex flex-1 justify-center flex-col-reverse items-center sm:justify-between sm:flex-row select-none">
      <div className="pt-2 sm:pt-0">
        <div className="text-sm font-normal flex items-center gap-3">
          <Dropdown
            label=""
            dismissOnClick={false}
            renderTrigger={() => (
              <div
                className={`px-4 py-3 border bg-greyMercury rounded-lg cursor-pointer flex items-center gap-2
              ${isActive ? " border-blue" : ""}`}
              >
                <span>{count}</span>{" "}
                <span>
                  {isActive ? <ArrowUp /> : <ArrowUp className="rotate-180" />}
                </span>
              </div>
            )}
          >
            <Dropdown.Item onClick={() => handlePageSize(2)}>2</Dropdown.Item>
            <Dropdown.Item onClick={() => handlePageSize(5)}>5</Dropdown.Item>
            <Dropdown.Item onClick={() => handlePageSize(10)}>10</Dropdown.Item>
            <Dropdown.Item onClick={() => handlePageSize(20)}>20</Dropdown.Item>
            <Dropdown.Item onClick={() => handlePageSize(50)}>50</Dropdown.Item>
            <Dropdown.Item onClick={() => handlePageSize(100)}>
              100
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handlePageSize(200)}>
              200
            </Dropdown.Item>
          </Dropdown>
          {total > 0 ? (
            <div>
              <span className="font-medium">{`${(active - 1) * count + 1}-${
                active * count < total ? active * count : total
              }`}</span>{" "}
              {t("utils.to")} <span className="font-medium">{total}</span>{" "}
              {pathname.includes("directories")
                ? t("pagination.reference")
                : t("pagination.patients")}
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <nav
          className="isolate inline-flex -space-x-px rounded-md text-dark font-normal text-sm"
          aria-label="Pagination"
        >
          {active > 1 ? (
            <Link
              to={`${link}?page=${
                active - 1
              }&per_page=${count}&${search_params}`}
              className="min-w-[36px] min-h-[36px] flex justify-center items-center"
            >
              <ArrowUp className="rotate-[-90deg]" />
            </Link>
          ) : (
            <span className="min-w-[36px] min-h-[36px] flex justify-center items-center">
              <ArrowUp className="rotate-[-90deg]" stroke="#c3c1c1" />
            </span>
          )}
          {/* <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
            ...
          </span> */}

          {active === pc && pc > 4 ? (
            <Link
              to={`${link}?page=${
                active - 4
              }&per_page=${count}&${search_params}`}
              className="min-w-[36px] min-h-[36px] flex justify-center items-center"
            >
              {active - 4}
            </Link>
          ) : null}
          {active - 3 > 0 && pc - active < 2 ? (
            <Link
              to={`${link}?page=${
                active - 3
              }&per_page=${count}&${search_params}`}
              className="min-w-[36px] min-h-[36px] flex justify-center items-center"
            >
              {active - 3}
            </Link>
          ) : null}
          {active - 2 > 0 ? (
            <Link
              to={`${link}?page=${
                active - 2
              }&per_page=${count}&${search_params}`}
              className="min-w-[36px] min-h-[36px] flex justify-center items-center"
            >
              {active - 2}
            </Link>
          ) : null}
          {active - 1 > 0 ? (
            <Link
              to={`${link}?page=${
                active - 1
              }&per_page=${count}&${search_params}`}
              className="min-w-[36px] min-h-[36px] flex justify-center items-center"
            >
              {active - 1}
            </Link>
          ) : null}
          <span className="min-w-[36px] min-h-[36px] flex justify-center items-center rounded-md bg-greyMercury">
            {active}
          </span>
          {pc - active >= 1 && pc > 1 ? (
            <Link
              to={`${link}?page=${
                active + 1
              }&per_page=${count}&${search_params}`}
              className="min-w-[36px] min-h-[36px] flex justify-center items-center"
            >
              {active + 1}
            </Link>
          ) : null}
          {pc - active >= 2 && pc > 2 ? (
            <Link
              to={`${link}?page=${
                active + 2
              }&per_page=${count}&${search_params}`}
              className="min-w-[36px] min-h-[36px] flex justify-center items-center"
            >
              {active + 2}
            </Link>
          ) : null}
          {pc - active >= 3 && active < 3 && pc > 3 ? (
            <Link
              to={`${link}?page=${
                active + 3
              }&per_page=${count}&${search_params}`}
              className="min-w-[36px] min-h-[36px] flex justify-center items-center"
            >
              {active + 3}
            </Link>
          ) : null}
          {active === 1 && pc > 4 ? (
            <Link
              to={`${link}?page=${
                active + 4
              }&per_page=${count}&${search_params}`}
              className="min-w-[36px] min-h-[36px] flex justify-center items-center"
            >
              {active + 4}
            </Link>
          ) : null}
          {active < pc ? (
            <Link
              to={`${link}?page=${
                active + 1
              }&per_page=${count}&${search_params}`}
              className="min-w-[36px] min-h-[36px] flex justify-center items-center"
            >
              <ArrowUp className="rotate-[90deg]" />
            </Link>
          ) : (
            <span className="min-w-[36px] min-h-[36px] flex justify-center items-center">
              <ArrowUp className="rotate-[90deg]" stroke="#c3c1c1" />
            </span>
          )}
        </nav>
      </div>
    </div>
  );
};
export default Pagination;
