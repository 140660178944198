import React from "react";

const StarCircleIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || "22"}
      height={props?.height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 21.75C5.06294 21.75 0.25 16.9371 0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75ZM12.2841 6.24584C12.0018 5.67388 11.5598 5.25 10.9986 5.25C10.438 5.25 9.9946 5.67311 9.70971 6.24459L9.70934 6.24534L8.76811 8.14335L8.76667 8.14629C8.73804 8.20523 8.67012 8.28823 8.56843 8.36425C8.46693 8.44014 8.36877 8.48142 8.30628 8.49226L8.30574 8.49236L6.60118 8.7779C5.98455 8.88152 5.46905 9.18394 5.30236 9.71024C5.13616 10.235 5.38082 10.7807 5.82111 11.2246L7.14739 12.5618C7.19992 12.6148 7.25876 12.7146 7.29566 12.8446C7.33232 12.9737 7.33557 13.0912 7.31895 13.1672L7.31872 13.1683L6.93962 14.821C6.78186 15.5084 6.8366 16.1894 7.3215 16.5461C7.80845 16.9044 8.473 16.7485 9.07676 16.3873L10.6728 15.4346L10.6738 15.4341C10.7453 15.3924 10.8631 15.3604 11.0013 15.3604C11.1405 15.3604 11.2558 15.3929 11.3229 15.433L12.9221 16.3875C13.5266 16.7475 14.192 16.9061 14.6789 16.5482C15.1641 16.1916 15.2162 15.5093 15.059 14.8213L14.6798 13.1683L14.6796 13.1672C14.6629 13.0912 14.6662 12.9737 14.7028 12.8446C14.7397 12.7146 14.7986 12.6148 14.8511 12.5618L16.1764 11.2256L16.1769 11.2251C16.62 10.7812 16.8655 10.2351 16.6979 9.70952C16.5302 9.18354 16.0137 8.88148 15.3975 8.77793L13.6915 8.49215C13.626 8.48113 13.5264 8.43938 13.4246 8.3637C13.3228 8.28793 13.2551 8.20511 13.2265 8.14629L12.2841 6.24584Z"
        fill={props?.fill || "#141B34"}
      />
    </svg>
  );
};

export default StarCircleIcon;
