import { Spinner } from "flowbite-react";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Card8Radius, Table } from "../../components/ui_components";
import { default_per_page } from "../../utils/constants";
import {
  objectToQueryString,
  queryStringToObject,
} from "../../utils/functions";
import Axios from "../../utils/httpClient";
import Pagination from "./Pagination";
import { useTranslation } from "react-i18next";

const ListSection = ({
  api_url,
  api_query = "",
  ThBlock = <></>,
  TdBlock = <></>,
  has_main_loading = false,
  listClass,
  has_pagination = true,
  defaultFilter = {},
  reload,
  setListData = () => {},
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { search, pathname } = useLocation();
  const [list, setList] = useState({});
  const [list_item, setListItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterQuery, setFilterQuery] = useState("");

  const [pageObj, setPageObj] = useState({
    page: 1,
    per_page: default_per_page,
  });

  useEffect(() => {
    const str_search = search.replace("?", "");
    let q_Obj = queryStringToObject(str_search);
    q_Obj = { ...defaultFilter, ...q_Obj };
    let p_Obj = {
      page: parseInt(get(q_Obj, "page", "1")),
      per_page: parseInt(get(q_Obj, "per_page", `${default_per_page}`)),
    };
    getList(
      objectToQueryString(
        has_pagination ? { ...q_Obj, ...p_Obj } : { ...q_Obj }
      )
    );
    setPageObj(p_Obj);
    delete q_Obj.page;
    delete q_Obj.per_page;
    setFilterQuery(objectToQueryString(q_Obj));
  }, [search, pathname, reload, i18n.language]);

  const getList = (s_params = "") => {
    if (has_main_loading) dispatch({ type: "SET_LOADING", payload: true });
    setLoading(true);
    Axios(api_url)
      .get(`${api_query}?${s_params}`)
      .then((r) => {
        setList(r?.data ?? {});
        setListData(r?.data?.data || []);
        const tt = [];
        (r?.data?.data).forEach((element) => {
          tt.push({ id: element?.id });
        });
        setListItem(tt);
      })
      .catch((e) => {})
      .finally(() => {
        if (has_main_loading) dispatch({ type: "SET_LOADING", payload: false });
        setLoading(false);
      });
  };

  return (
    <div className={`px-6 ${listClass}`}>
      {loading ? (
        <Card8Radius className="flex justify-center items-center">
          <Spinner
            size={"xl"}
            className={"fill-blue h-6 w-6 text-center"}
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: "300px",
              alignItems: "center",
            }}
          />
        </Card8Radius>
      ) : (
        <>
          <div className="rounded-tl-[8px] rounded-tr-[8px] overflow-hidden relative">
            <div className="absolute top-0 left-0 w-[7px] h-full bg-transparent rounded-tl-[8px] border-t border-l border-greyMercury" />
            <div className="absolute top-0 right-0 w-[7px] h-full bg-transparent rounded-tr-[8px] border-t border-r  border-greyMercury" />
            {list?.data?.length ? (
              <Table
                th={<ThBlock list_item={list_item} />}
                td={list?.data?.map((item, index) => (
                  <TdBlock td_item={item} td_index={index} key={index} />
                ))}
              />
            ) : (
              <div className="min-h-[300px] border-t border-greyMercury flex justify-center items-center flex-col">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.4673 21.528C20.7589 21.8222 21.2338 21.8243 21.528 21.5327C21.8222 21.2411 21.8243 20.7662 21.5327 20.472L20.4673 21.528ZM17.2365 18.2682L20.4673 21.528L21.5327 20.472L18.3019 17.2123L17.2365 18.2682ZM20.35 13.3C20.35 9.40639 17.1936 6.25 13.3 6.25V7.75C16.3652 7.75 18.85 10.2348 18.85 13.3H20.35ZM13.3 6.25C9.40639 6.25 6.25 9.40639 6.25 13.3H7.75C7.75 10.2348 10.2348 7.75 13.3 7.75V6.25ZM6.25 13.3C6.25 17.1936 9.40639 20.35 13.3 20.35V18.85C10.2348 18.85 7.75 16.3652 7.75 13.3H6.25ZM13.3 20.35C17.1936 20.35 20.35 17.1936 20.35 13.3H18.85C18.85 16.3652 16.3652 18.85 13.3 18.85V20.35Z"
                    fill="#5D0D56"
                  />
                  <path
                    d="M4 10C3.51076 9.96288 3.16925 9.87932 2.88886 9.69117C2.67048 9.54463 2.48298 9.35634 2.33706 9.13703C2 8.63045 2 7.92524 2 6.51483C2 5.10442 2 4.39921 2.33706 3.89263C2.48298 3.67332 2.67048 3.48502 2.88886 3.33849C3.39331 3 4.09554 3 5.5 3H18.5C19.9045 3 20.6067 3 21.1111 3.33849C21.3295 3.48502 21.517 3.67332 21.6629 3.89263C22 4.39921 22 5.10442 22 6.51483C22 7.80256 22 8.50243 21.7435 9"
                    stroke="#5D0D56"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                <div>{t("report7.no_data")}</div>
              </div>
            )}
          </div>
          <div className="border border-greyMercury border-t-0 px-4 rounded-bl-[8px] rounded-br-[8px] overflow-hidden mb-3">
            {has_pagination ? (
              <Pagination
                active={pageObj?.page ? pageObj?.page : 1}
                count={pageObj?.per_page}
                total={list?.meta?.total}
                link={pathname}
                search_params={filterQuery}
              />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default ListSection;
