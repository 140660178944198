import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTH_URL, SSO_CLIENT_ID, SSO_URL } from '../../utils/api';
import Axios from '../../utils/httpClient';
import { setToken } from '../../utils/tokenStorge';

function CallBack() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const getToken = (url, params, success, error) => {
		// console.log("getToken", url, params, success, error);
		var request = new XMLHttpRequest();
		request.open('POST', url, true);
		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
		request.onload = function () {
			var body = {};
			try {
				body = JSON.parse(request.response);
			} catch (e) {}
			if (request.status === 200) {
				success(request, body);
			} else {
				error(request, body);
			}
		};
		request.onerror = function () {
			error(request, {});
		};
		var body = Object.keys(params)
			.map(key => key + '=' + params[key])
			.join('&');
		request.send(body);
	};

	const parseQueryString = string => {
		if (string === '') {
			return {};
		}
		var segments = string.split('&').map(s => s.split('='));
		var queryString = {};
		segments.forEach(s => (queryString[s[0]] = s[1]));
		return queryString;
	};
	const getCancerToken = access_token => {
		Axios(AUTH_URL, access_token)
			.post('api/v1/get-token')
			.then(r => {
				setToken(r?.data?.access_token);
				localStorage.setItem('refresh_token', r?.data?.refresh_token);
				const currentdate = new Date();
				const expiresdate = new Date(currentdate.getTime() + parseInt(r?.data?.expires_in) * 1000);
				localStorage.setItem('expires_in', r?.data?.expires_in);
				localStorage.setItem('expires_date', expiresdate);
				dispatch({ type: 'SET_LOADING', payload: false });
				navigate('/patients');
			})
			.catch(() => {})
			.finally(() => {
				// dispatch({ type: "SET_LOADING", payload: false });
			});
	};

	useEffect(() => {
		const q = parseQueryString(window.location.search.substring(1));
		// console.log(q);

		if (q.error) {
			alert(t('callback.authorization_err') + q.error);
			document.getElementById('error_details').innerText = q.error + '\n\n' + q.error_description;
			document.getElementById('error').classList = '';
		}

		if (q.code) {
			// console.log("q.state", q.state);
			if (true) {
				dispatch({ type: 'SET_LOADING', payload: true });
				const originURL = window.location.origin;
				getToken(
					`${SSO_URL}oauth/token`,
					{
						grant_type: 'authorization_code',
						code: q.code,
						client_id: SSO_CLIENT_ID,
						redirect_uri: `${originURL}/auth/callback`,
						code_verifier: localStorage.getItem('pkce_code_verifier'),
						claims: 'organization',
					},
					function (request, body) {
						const currentdate = new Date();
						const expiresdate = new Date(currentdate.getTime() + parseInt(body?.expires_in) * 1000);
						localStorage.setItem('sso_access_token', body?.access_token);
						localStorage.setItem('sso_refresh_token', body?.refresh_token);
						localStorage.setItem('sso_expires_in', body?.expires_in);
						localStorage.setItem('sso_expires_date', expiresdate);
						getCancerToken(body?.access_token);
						document.getElementById('error_details').innerText = JSON.stringify(body);
					},
					function (request, error) {
						document.getElementById('error_details').innerText = error.error + '\n\n' + error.error_description;
						document.getElementById('error').classList = '';
						dispatch({ type: 'SET_LOADING', payload: false });
					}
				);
			}
		}
		//   });
	}, []);

	return (
		<div className='text-center py-10 px-6'>
			<div id='error'>{t('callback.callback')}</div>
			<div id='error_details'></div>
		</div>
	);
}

export default CallBack;
