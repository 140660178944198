import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../assets/svg/ArrowRight";
import YearSelect from "../../sections/ReportSection/YearSelect";
const HeaderBar = ({ label, isBack = true, action = null }) => {
  const navigate = useNavigate();
  return (
    // <div className='flex items-center py-4 px-6 gap-4 border-t-[1px] border-t-[blueZumthor] border-b-[1px] border-b-[blueZumthor]'>
    <div className="flex items-center justify-between py-4 px-6 gap-4 border-b-[1px] border-b-[blueZumthor]">
      <div className="flex items-center px-6 gap-4">
        {isBack ? (
          <ArrowRight
            className="rotate-[180deg] cursor-pointer"
            onClick={() => navigate(-1)}
          />
        ) : (
          false
        )}
        <p className="text-dark text-[18px] font-semibold ">{label}</p>
      </div>
      <div>{action}</div>
    </div>
  );
};

export default HeaderBar;
