import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Callback2 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      const clientId = "cancer";
      const authUrl = `https://sso.medcore.uz/sso/app/user/login?client_id=${clientId}&code=${code}`;

      axios
        .get(authUrl, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer your_bearer_token`,
          },
        })
        .then((response) => {
          const { access_token } = response.data;
          console.log("access_token", access_token);
          //   localStorage.setItem("access_token", access_token);
          //   navigate("/dashboard");
        })
        .catch((error) => {
          console.error("Error during token exchange:", error);
        });
    }
  }, [navigate]);

  return (
    <div>
      <h1>Authenticating...</h1>
    </div>
  );
};

export default Callback2;
