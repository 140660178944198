import React, { useEffect, useId, useRef, useState } from "react";

const Table = ({ th, td }) => {
  const uniqueId = `table__${useId()}`;
  const [tableWidth, setTableWidth] = useState("100%");
  const wrapper1Ref = useRef(null);
  const wrapper2Ref = useRef(null);
  const updateTableWidth = () => {
    const tableElement = window.document.getElementById(uniqueId);
    if (tableElement) {
      setTableWidth(tableElement.offsetWidth - 0.5);
    }
  };
  const synchronizeScroll = (source, target) => {
    if (target && source) {
      target.scrollLeft = source.scrollLeft;
    }
  };
  useEffect(() => {
    updateTableWidth();
    const handleResize = () => updateTableWidth();
    window.addEventListener("resize", handleResize);
    const wrapper1 = wrapper1Ref.current;
    const wrapper2 = wrapper2Ref.current;
    const handleScrollWrapper1 = () => synchronizeScroll(wrapper1, wrapper2);
    const handleScrollWrapper2 = () => synchronizeScroll(wrapper2, wrapper1);
    if (wrapper1 && wrapper2) {
      wrapper1.addEventListener("scroll", handleScrollWrapper1);
      wrapper2.addEventListener("scroll", handleScrollWrapper2);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
      if (wrapper1 && wrapper2) {
        wrapper1.removeEventListener("scroll", handleScrollWrapper1);
        wrapper2.removeEventListener("scroll", handleScrollWrapper2);
      }
    };
  }, []);

  return (
    <>
      <div
        ref={wrapper1Ref}
        className="wrapper1 overflow-x-auto overflow-y-hidden relative"
      >
        <div
          style={{
            width: tableWidth,
          }}
          className="text-transparent bg-transparent h-[1px]"
        >
          .
        </div>
      </div>
      <div
        ref={wrapper2Ref}
        className="wrapper2 overflow-x-auto overflow-y-hidden relative"
      >
        <table id={uniqueId} className="table-auto w-full">
          <thead>{th}</thead>
          <tbody>{td}</tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
