import { Modal } from "flowbite-react";
import { useTranslation } from "react-i18next";
const SearchModal = ({ openModal, setOpenModal }) => {
	const {t}=useTranslation()
  return (
    <>
      <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {t("search_modal.title1")}
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {t("search_modal.title2")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default SearchModal;
