import { findIndex } from "lodash";
import React, { useRef, useState, useEffect } from "react";

const SlidingTabs = ({ active = 0, tabs = [] }) => {
  const tabsRef = useRef([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  useEffect(() => {
    const t_index = findIndex(tabs, (o) => o.id === active);
    setActiveTabIndex(t_index || 0);
  }, [active]);
  useEffect(() => {
    if (activeTabIndex === null) {
      return;
    }
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };
    setTabPosition();
  }, [activeTabIndex]);
  return (
    <div className="w-full overflow-x-auto">
      <div className="flex">
        <div className="w-full flex-row gap-6 relative mx-auto inline-flex ">
          <span
            className="absolute bottom-0 top-0 -z-10 flex overflow-hidden transition-all duration-300"
            style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
          >
            <span className="h-full w-full border-b-2 border-purple" />
          </span>
          {tabs.map((tab, index) => {
            const isActive = activeTabIndex === index;
            return (
              <button
                key={index}
                ref={(el) => (tabsRef.current[index] = el)}
                className={`${
                  isActive ? `text-dark` : `text-blueWaterloo`
                } flex items-center gap-2 my-auto cursor-pointer py-2 select-none rounded-full text-center text-base font-medium`}
                onClick={() => {
                  setActiveTabIndex(index);
                  tab?.clickEvent();
                }}
              >
                <span>{tab.name}</span>
                <span className="text-xs font-normal px-1 py-0.5 rounded-[100px] bg-purplelight">
                  {tab.count}
                </span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SlidingTabs;
