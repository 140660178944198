import React from "react";

const ArchiveIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || "22"}
      height={props?.height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 15C1 12.6611 1 11.4917 1.53647 10.6379C1.81621 10.1927 2.19267 9.81621 2.63789 9.53647C3.49167 9 4.66111 9 7 9H15C17.3389 9 18.5083 9 19.3621 9.53647C19.8073 9.81621 20.1838 10.1927 20.4635 10.6379C21 11.4917 21 12.6611 21 15C21 17.3389 21 18.5083 20.4635 19.3621C20.1838 19.8073 19.8073 20.1838 19.3621 20.4635C18.5083 21 17.3389 21 15 21H7C4.66111 21 3.49167 21 2.63789 20.4635C2.19267 20.1838 1.81621 19.8073 1.53647 19.3621C1 18.5083 1 17.3389 1 15Z"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 9C19 7.59987 19 6.8998 18.7275 6.36502C18.4878 5.89462 18.1054 5.51217 17.635 5.27248C17.1002 5 16.4001 5 15 5H7C5.59987 5 4.8998 5 4.36502 5.27248C3.89462 5.51217 3.51217 5.89462 3.27248 6.36502C3 6.8998 3 7.59987 3 9"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 5C17 3.11438 17 2.17157 16.4142 1.58579C15.8284 1 14.8856 1 13 1H9C7.11438 1 6.17157 1 5.58579 1.58579C5 2.17157 5 3.11438 5 5"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13C14 14.1046 13.1046 15 12 15H10C8.89543 15 8 14.1046 8 13"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArchiveIcon;
