import React from 'react';

const NoData = ({ label, icon }) => {
	return (
		<div className='rounded-lg bg-white w-full min-h-[635px] border-[1px] border-greyMercury flex justify-center items-center'>
			<div className='flex flex-col justify-center items-center gap-4'>
				{icon}
				<p className='text-14 text-dark w-3/4  text-center'>{label}</p>
			</div>
		</div>
	);
};

export default NoData;
