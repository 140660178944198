import React from 'react';
import { CloseIcon } from '../../../assets/svg';

export default function Drawer({ children, isOpen, setIsOpen }) {
	return (
		<main
			className={
				'fixed overflow-hidden z-[11] bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ' +
				(isOpen ? ' transition-opacity opacity-100 duration-500 translate-x-0' : ' transition-all delay-500 opacity-0 translate-x-full ')
			}
		>
			<section
				className={
					' w-screen  right-0 absolute max-w-sm bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ' +
					(isOpen ? ' translate-x-0 ' : ' translate-x-full ')
				}
			>
				<article className='relative w-screen max-w-lg pb-10 flex flex-col space-y-6 overflow-y-scroll h-full p-6'>
					<div
						className='flex cursor-pointer'
						onClick={() => {
							setIsOpen(false);
						}}
					>
						<CloseIcon />
					</div>
					{children}
				</article>
			</section>
			<section
				className=' w-screen h-full cursor-pointer '
				onClick={() => {
					setIsOpen(false);
				}}
			></section>
		</main>
	);
}
