import React, { useState, useEffect, useRef } from "react";
import { CalendarIcon } from "../../assets/svg";
import { find, get } from "lodash";
import { useTranslation } from "react-i18next";

const YearSelect = ({ value = null, setValue }) => {
  const {t}=useTranslation()
  const [isOpen, setisOpen] = useState(false);
  const ref = useRef();
  const years = [
    { value: null, label: t("bool.all") },
    { value: 2025, label: 2025 },
    { value: 2024, label: 2024 },
    { value: 2023, label: 2023 },
    { value: 2022, label: 2022 },
    { value: 2021, label: 2021 },
    { value: 2020, label: 2020 },
  ];
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setisOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="relative" ref={ref}>
      <button
        onClick={() => setisOpen(!isOpen)}
        className={`group relative w-full lg:!text-sm !text-xs overflow-hidden bg-greylight px-[10px] py-[2px] rounded-lg border border-[#E1E2E7]`}
      >
        <div className="relative z-10 flex items-center gap-2 justify-between text-lightGray h-[42px] w-full">
          <div
            className={`flex items-center transition-all ease-in-out duration-200 w-full`}
          >
            <span className="mr-2">
              <CalendarIcon />
            </span>
            <div className="flex flex-col items-start w-full">
              <span className="flex lg:gap-3 gap-1 items-center">
                <span>
                  {get(
                    years.find((o) => o.value === value),
                    "label",
                    ""
                  )}
                </span>
              </span>
            </div>
          </div>
        </div>
        <span className="absolute top-0 left-0 z-[1] w-full h-full backdrop-blur-lg bg-blue-500/10" />
      </button>
      {isOpen ? (
        <div className="absolute bg-white shadow-[0_4px_15px_0px_rgba(0,0,0,0.08)] w-full rounded-lg py-2 z-9999">
          {years.map((item, index) => (
            <button
              key={index}
              className="block text-sm p-2 hover:bg-greylight hover:text-blue w-full"
              onClick={() => {
                setValue(item?.value);
                setisOpen(false);
              }}
            >
              {item?.label}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default YearSelect;
