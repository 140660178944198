import React from "react";

const DeleteIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || "18"}
      height={props?.height || "20"}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.25 4.58301L14.7336 12.9373C14.6016 15.0717 14.5356 16.1389 14.0006 16.9062C13.7361 17.2856 13.3955 17.6057 13.0006 17.8464C12.2018 18.333 11.1325 18.333 8.99395 18.333C6.8526 18.333 5.78192 18.333 4.98254 17.8455C4.58733 17.6044 4.24666 17.2837 3.98224 16.9037C3.4474 16.1352 3.38288 15.0664 3.25384 12.929L2.75 4.58301"
        stroke="#F44444"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1.5 4.58317H16.5M12.3798 4.58317L11.8109 3.40961C11.433 2.63005 11.244 2.24027 10.9181 1.99718C10.8458 1.94325 10.7692 1.89529 10.6892 1.85375C10.3283 1.6665 9.8951 1.6665 9.02877 1.6665C8.14069 1.6665 7.69665 1.6665 7.32974 1.8616C7.24842 1.90484 7.17082 1.95475 7.09774 2.01081C6.76803 2.26375 6.58386 2.6678 6.2155 3.47589L5.71077 4.58317"
        stroke={props?.stroke || "#F44444"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.91602 13.75L6.91602 8.75"
        stroke={props?.stroke || "#F44444"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.084 13.75L11.084 8.75"
        stroke={props?.stroke || "#F44444"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DeleteIcon;
