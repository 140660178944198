import React from "react";

const SearchIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || "22"}
      height={props?.height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 16.5L21 21"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10Z"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
