import React from "react";

const Status = (props) => {
  return (
    <div
      {...props}
      className={`px-2 py-1 rounded-xl text-xs font-normal text-center text-wrap inline-block  whitespace-nowrap ${
        props?.type_status === "ts_blue"
          ? "text-blue bg-blueZumthor"
          : props?.type_status === "ts_orange"
          ? "text-orange bg-orangelight"
          : props?.type_status === "ts_red"
          ? "text-red bg-redlight"
          : props?.type_status === "ts_green"
          ? "text-green bg-greenlight"
          : props?.type_status === "ts_grey"
          ? "text-blueWaterloo bg-greyMercury"
          : ` `
      } `}
    >
      {props?.text}
    </div>
  );
};

export default Status;
