import { Dropdown } from "flowbite-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { InternetIcon } from "../../assets/svg";
import ArrowDown from "../../assets/svg/ArrowDown";
import HamburgerMenu from "../../assets/svg/HamburgerMenu";
import { default_per_page } from "../../utils/constants";
import { getLanguage } from "../../utils/language";
import { useRole, userRoles } from "../../utils/roles";
import { removeToken } from "../../utils/tokenStorge";
import Drawer from "./modal/drawer";
import SearchModal from "./modal/search_modal";
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((s) => s);
  const [openModal, setOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { hasRole } = useRole();

  const lan = getLanguage();
  const nav = [
    {
      link: `/patients?page=1&per_page=${default_per_page}&status=2`,
      name: t("header.patients"),
    },
    { link: "/lists", name: t("header.lists") },
    { link: "/reports", name: t("header.reports") },
    { link: "/", name: t("header.statistics") },
    { link: "/logs", name: t("patients_form.logsss") },
    { link: "/directories", name: t("header.directories") },
    // { link: "/settings", name: t("header.settings") },
  ];
  const nav2 = [
    {
      link: `/patients?page=1&per_page=${default_per_page}&status=2`,
      name: t("header.patients"),
    },

    { link: "/directories", name: t("header.directories") },
    // { link: "/settings", name: t("header.settings") },
  ];

  const logOut = () => {
    removeToken();
    dispatch({ type: "SET_USER", payload: {} });
    navigate("/login");
  };
  const onLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
    window.localStorage.setItem("language", newLang);
  };
  return (
    <div className="bg-bluedeep  py-[15px] px-6">
      <div className="container_main flex justify-between items-center">
        <div className="flex gap-8 items-center">
          <Link to={"/patients"}>
            <img src="/images/logo2.svg" alt="" />
          </Link>
          <div className="hidden lg:flex gap-2">
            {user?.id ? (
              <>
                {(hasRole(userRoles?.reg) ? nav2 : nav)?.map((item, index) => {
                  return (
                    <>
                      <NavLink
                        to={item?.link}
                        className={({ isActive }) =>
                          isActive
                            ? "hover:bg-bluePort bg-bluePort  px-[14px] py-3 hover:text-white text-white rounded-lg hover:transform-gpu transform-gpu hover:scale-95 scale-95 transition-all"
                            : "text-blueWaterloo hover:bg-bluePort px-[14px] py-3 hover:text-white rounded-lg hover:transform-gpu hover:scale-95  transition-all"
                        }
                        key={index}
                      >
                        {item?.name}
                      </NavLink>
                    </>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
        <div className="flex items-center z-999 gap-4">
          {user?.id ? (
            <>
              {/* <div
                onClick={() => setOpenModal(true)}
                className="flex px-4 py-3 rounded-lg bg-bluePort gap-3 items-center cursor-pointer h-[40px]"
              >
                <SearchIcon stroke="white" width={16} height={16} />
                <div className="text-blueWaterloo text-sm w-28 hidden xl:block">
                  {t("header.search")}
                </div>
                <div className="py-[2px] px-1 border-greyFiord border-[1px] rounded text-blueWaterloo text-xs hidden xl:block">
                  Ctrl+F
                </div>
              </div> */}
              <Dropdown
                label=""
                renderTrigger={() => (
                  <div className="p-1 pr-3 flex gap-2 bg-bluePort rounded-100 items-center cursor-pointer">
                    <div className="w-8 h-8 bg-blueFiord rounded-full flex justify-center items-center text-white">
                      {(user?.name ?? "").charAt(0)}
                    </div>
                    <ArrowDown width={8} height={4} stroke={"#ffff"} />
                  </div>
                )}
              >
                <Dropdown.Item>
                  <div className=" text-gray-500 font-medium">
                    {user?.name ?? ""}
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={logOut}>
                  {" "}
                  {t("header.exit")}
                </Dropdown.Item>
              </Dropdown>
            </>
          ) : (
            <NavLink
              to={"/login"}
              className={({ isActive }) =>
                isActive
                  ? ""
                  : "hover:bg-bluePort bg-bluePort  px-[14px] py-3 hover:text-white text-white rounded-lg hover:transform-gpu transform-gpu hover:scale-95 scale-95 transition-all"
              }
            >
              {t("header.enter")}
            </NavLink>
          )}
          <div className="hidden z-9999 sm:block">
            <Dropdown
              label=""
              renderTrigger={() => (
                <div className="p-1 pr-3 flex gap-2 bg-bluePort rounded-100 items-center cursor-pointer">
                  <div className="w-8 h-8 bg-blueFiord  rounded-full flex justify-center items-center ">
                    <InternetIcon stroke={"#fff"} />
                  </div>
                  <div className="text-white hidden md:block">
                    {lan === "uz" ? "Ўз" : lan === "ru" ? "Ру" : "Eng"}
                  </div>
                  <ArrowDown width={8} height={4} stroke={"#ffff"} />
                </div>
              )}
            >
              {[
                { namelng: "Русский", lng: "ru" },
                { namelng: "Ўзбекча", lng: "uz" },
                // { namelng: "English", lng: "en" },
              ]?.map((item, index) => {
                return (
                  <Dropdown.Item
                    dismissOnClick={false}
                    key={index}
                    onClick={() => {
                      onLanguage(item?.lng);
                    }}
                  >
                    <div className={lan === item?.lng ? "text-blue" : ""}>
                      {item?.namelng}
                    </div>
                  </Dropdown.Item>
                );
              })}
            </Dropdown>
          </div>
          {user?.id ? (
            <>
              <div className="flex lg:hidden ">
                <div
                  className="rounded-lg bg-bluePort p-3 h-[40px]"
                  onClick={() => setIsOpen(true)}
                >
                  <HamburgerMenu stroke={"#ffff"} />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <SearchModal openModal={openModal} setOpenModal={setOpenModal} />
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex flex-col">
          {(hasRole(3) ? nav2 : nav)?.map((item, index) => (
            <NavLink
              to={item?.link}
              className={
                "text-blueWaterloo hover:bg-bluePort px-[14px] py-3 hover:text-white rounded-lg hover:transform-gpu hover:scale-95  transition-all"
              }
              key={index}
            >
              {item?.name}
            </NavLink>
          ))}
          <ul className="flex gap-2 mt-4 sm:hidden">
            {[
              { namelng: "Русский", lng: "ru" },
              { namelng: "Ўзбекча", lng: "uz" },
              // { namelng: "English", lng: "en" },
            ]?.map((item, index) => {
              return (
                <li
                  className={
                    item?.lng === lan
                      ? "p-3 text-blue cursor-pointer"
                      : "p-3 text-blueWaterloo cursor-pointer"
                  }
                  key={index}
                  onClick={() => {
                    onLanguage(item?.lng);
                  }}
                >
                  {item?.namelng}
                </li>
              );
            })}
          </ul>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
