// // **api server

export const MAIN_URL = process.env.REACT_APP_API_MAIN_URL;
export const GUIDE_URL = process.env.REACT_APP_API_GUIDE_URL;
export const AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
export const SSV_URL = process.env.REACT_APP_API_SSV_URL;
export const SSO_URL = process.env.REACT_APP_API_SSO_URL;
export const SSO_CLIENT_ID = process.env.REACT_APP_API_SSO_CLIENT_ID;

export const API_URL = MAIN_URL;
