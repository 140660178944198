import React from "react";

const InternetIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || "24"}
      height={props?.height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
      />
      <path
        d="M12 22C14.2091 22 16 17.5228 16 12C16 6.47715 14.2091 2 12 2C9.79086 2 8 6.47715 8 12C8 17.5228 9.79086 22 12 22Z"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
      />
      <path
        d="M2 12H22"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InternetIcon;
