import React from "react";
import axios from "axios";

const Login2 = () => {
  const clientId = "cancer";
  const redirectUrl = "http://localhost:8082/auth/callback2";
  const client_secret =
    "7e63fbfd400c3cf217b4409e1a48adef4d18fe4f424b2aa4fe2c7953";
  const sendAuthRequest = async () => {
    try {
      const response = await axios.post(
        "https://rc.medcore.uz/sso/auth/app?client_id=" + clientId,
        {},
        {
          headers: {
            accept: "application/json",
            "X-Client-Secret": client_secret,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error during authentication request:", error);
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <button onClick={sendAuthRequest}>Login with SSO</button>
    </div>
  );

  // const Login2 = () => {
  // 	const handleLogin = () => {
  // 		const clientId = 'your_client_id';
  // 		const redirectUrl = 'https://your-app.com/callback';
  // 		window.location.href = `https://sso.medcore.uz/oauth?client_id=${clientId}&redirect_url=${redirectUrl}`;
  // 	};

  // 	return (
  // 		<div>
  // 			<h1>Login</h1>
  // 			<button onClick={handleLogin}>Login with SSO</button>
  // 		</div>
  // 	);
};
export default Login2;
