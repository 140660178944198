export const default_per_page = 10;

export const reactSelectConfiguration = {
	isSearchable: true,
	isMulti: false,
	styles: {
		option: (provided, { isFocused, isSelected }) => ({
			...provided,
			color: isSelected || isFocused ? '#3164F7' : '#0A0D2C',
			background: 'white',
			width: '100%',
			cursor: 'pointer',
			opacity: isFocused ? '0.8' : '',
			padding: '8px',
			transition: '400ms',
			fontSize: '13px',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '13px',
			},
		}),
		container: provided => ({
			...provided,
			width: '100%',
		}),
		singleValue: provided => ({
			...provided,
			color: 'var(--bold)',
		}),
		control: (provided, state) => ({
			...provided,
			outline: 'none',
			borderRadius: 8,
			border: state?.isSelected || state?.isFocused ? '1px solid #3164F7' : '1px solid #E3E3E3',
			cursor: 'pointer',
			color: '#0A0D2C',
			minHeight: 44,
			margin: '0',
			paddingLeft: '16px',
			paddingRight: '16px',
			boxShadow: '',
			transition: '400ms',
			'@media (max-width: 1024px)': {
				...provided['@media (max-width: 1024px)'],
				paddingLeft: '12px',
				paddingRight: '12px',
			},
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '12px',
			},
			':hover': {
				border: '1px solid #3164F7',
			},
		}),
		menuPortal: provided => ({
			...provided,
			zIndex: 999999999,
		}),
		menu: provided => ({
			...provided,
			margin: '0 0 10px 0',
			width: '100%',
			padding: '0 16px',
			border: '0!important',
			borderRadius: 8,
			boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.08)',
			background: 'white',
			overflow: 'hidden',
			// top: "50px",
		}),
		menuList: provided => ({
			...provided,
			padding: 0,
			height: 'auto',
			zIndex: 11111111,
			'::-webkit-scrollbar': {
				width: '4px',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-track': {
				background: '#F5F5F5',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#767993',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb:hover': {
				// background: '#555',
			},
		}),
		indicatorSeparator: provided => ({
			...provided,
			display: 'none',
		}),
		indicatorsContainer: (provided, { isFocused }) => ({
			...provided,
			height: '100%',
			paddingRight: '0px',
		}),
		dropdownIndicator: (provided, { isFocused }) => ({
			...provided,
			color: 'var(--grey)',
			paddingRight: isFocused ? '8px' : '0px',
			paddingLeft: isFocused ? '0px' : '8px',
			transform: isFocused ? 'rotate(180deg)' : '',
			':hover': {
				color: 'var(--grey)',
			},
		}),
		valueContainer: provided => ({
			...provided,
			padding: '0',
			fontWeight: '500',
			alignContent: 'center',
			fontSize: '14px',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '14px',
			},
		}),
		noOptionsMessage: provided => ({
			...provided,
			color: 'var(--bold)',
			width: '100%',
			cursor: 'pointer',
			height: 48,
			padding: '14px 12px',
			textAlign: 'left',
			fontSize: '15px',
		}),
		placeholder: provided => ({
			...provided,
			fontSize: '',
			color: '#767993',
		}),
	},
};

export const reactSelectConfigurationMulti = {
	isSearchable: true,
	isMulti: false,
	styles: {
		option: (provided, { isFocused, isSelected }) => ({
			...provided,
			color: isSelected || isFocused ? '#3164F7' : '#0A0D2C',
			background: 'white',
			width: '100%',
			cursor: 'pointer',
			opacity: isFocused ? '0.8' : '',
			padding: '8px',
			transition: '400ms',
			fontSize: '14px',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '14px',
			},
		}),
		container: provided => ({
			...provided,
			width: '100%',
		}),
		singleValue: provided => ({
			...provided,
			color: 'var(--bold)',
		}),
		control: (provided, state) => ({
			...provided,
			outline: 'none',
			borderRadius: 8,
			border: state?.isSelected || state?.isFocused ? '1px solid #3164F7' : '1px solid #E3E3E3',
			cursor: 'pointer',
			color: '#0A0D2C',
			minHeight: 44,
			margin: '0',
			boxShadow: '',
			transition: '400ms',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '12px',
			},
			':hover': {
				border: '1px solid #3164F7',
			},
		}),
		menuPortal: provided => ({
			...provided,
			zIndex: 999999999,
		}),
		menu: provided => ({
			...provided,
			margin: '0 0 10px 0',
			width: '100%',
			padding: '0 16px',
			border: '0!important',
			borderRadius: 8,
			boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.08)',
			background: 'white',
			overflow: 'hidden',
			// top: "50px",
			'@media (max-width: 1024px)': {
				...provided['@media (max-width: 1024px)'],
				padding: '0 12px',
			},
		}),
		menuList: provided => ({
			...provided,
			padding: 0,
			height: 'auto',
			zIndex: 11111111,
			'::-webkit-scrollbar': {
				width: '4px',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-track': {
				background: '#F5F5F5',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#767993',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb:hover': {
				// background: '#555',
			},
		}),
		indicatorSeparator: provided => ({
			...provided,
			display: 'none',
		}),

		indicatorsContainer: (provided, { isFocused }) => ({
			...provided,
			height: '100%',
			paddingRight: '0px',
		}),
		dropdownIndicator: (provided, { isFocused }) => ({
			...provided,
			color: 'var(--grey)',
			paddingRight: isFocused ? '8px' : '0px',
			paddingLeft: isFocused ? '0px' : '8px',
			transform: isFocused ? 'rotate(180deg)' : '',
			':hover': {
				color: 'var(--grey)',
			},
		}),
		// valueContainer: (provided) => ({
		//   ...provided,
		//   padding: "0",
		//   fontWeight: "500",
		//   alignContent: "center",
		//   fontSize: "14px",
		//   "@media (max-width: 768px)": {
		//     ...provided["@media (max-width: 768px)"],
		//     fontSize: "14px",
		//   },
		// }),

		valueContainer: (baseStyles, state) => ({
			...baseStyles,
			display: 'flex',
		}),
		multiValueLabel: (baseStyles, state) => ({
			...baseStyles,
			maxWidth: '60px',
			fontSize: '14px',
			fontWeight: '400',
			color: '#0A0D2C',
			padding: 0,
		}),
		multiValue: (baseStyles, state) => ({
			...baseStyles,
			maxWidth: '80px',
			backgroundColor: '#F5F5F5',
			borderRadius: '4px',
			padding: '4px 6px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			gap: '4px',
		}),
		multiValueRemove: (baseStyles, state) => ({
			...baseStyles,
			height: 'max-content',
			padding: '0',
			color: '#0A0D2C',
		}),
		noOptionsMessage: provided => ({
			...provided,
			color: 'var(--bold)',
			width: '100%',
			cursor: 'pointer',
			height: 48,
			padding: '14px 12px',
			textAlign: 'left',
			fontSize: '15px',
		}),
		placeholder: provided => ({
			...provided,
			fontSize: '',
			color: '#767993',
		}),
	},
};
export const reactSelectConfiguration2 = {
	isSearchable: true,
	isMulti: false,
	styles: {
		option: (provided, { isFocused, isSelected }) => ({
			...provided,
			color: isSelected || isFocused ? '#3164F7' : '#0A0D2C',
			background: 'white',
			width: '100%',
			cursor: 'pointer',
			opacity: isFocused ? '0.8' : '',
			padding: '8px',
			transition: '400ms',
			fontSize: '14px',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '14px',
			},
		}),
		container: provided => ({
			...provided,
			width: '100%',
		}),
		singleValue: provided => ({
			...provided,
			color: 'var(--bold)',
		}),
		control: (provided, state) => ({
			...provided,
			outline: 'none',
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			borderTopRightRadius: 8,
			borderBottomRightRadius: 8,
			border: state?.isSelected || state?.isFocused ? '1px solid #3164F7' : '1px solid #E3E3E3',
			cursor: 'pointer',
			color: '#0A0D2C',
			height: 44,
			margin: '0',
			paddingLeft: '16px',
			paddingRight: '16px',
			boxShadow: '',
			transition: '400ms',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '12px',
				paddingLeft: '12px',
				paddingRight: '12px',
			},
			':hover': {
				border: '1px solid #3164F7',
			},
		}),
		menuPortal: provided => ({
			...provided,
			zIndex: 999999999,
		}),
		menu: provided => ({
			...provided,
			margin: 0,
			width: '100%',
			padding: 16,
			border: '0!important',
			borderRadius: 8,
			boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.08)',
			background: 'white',
			overflow: 'hidden',
			top: '50px',
		}),
		menuList: provided => ({
			...provided,
			padding: 0,
			height: 'auto',
			zIndex: 11111111,
			'::-webkit-scrollbar': {
				width: '4px',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-track': {
				background: '#F5F5F5',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#767993',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb:hover': {
				// background: '#555',
			},
		}),
		indicatorSeparator: provided => ({
			...provided,
			display: 'none',
		}),
		indicatorsContainer: (provided, { isFocused }) => ({
			...provided,
			height: '100%',
			paddingRight: '0px',
		}),
		dropdownIndicator: (provided, { isFocused }) => ({
			...provided,
			color: 'var(--grey)',
			paddingRight: isFocused ? '8px' : '0px',
			paddingLeft: isFocused ? '0px' : '8px',
			transform: isFocused ? 'rotate(180deg)' : '',
			':hover': {
				color: 'var(--grey)',
			},
		}),
		valueContainer: provided => ({
			...provided,
			padding: '0',
			fontWeight: '500',
			alignContent: 'center',
			fontSize: '14px',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '14px',
			},
		}),
		noOptionsMessage: provided => ({
			...provided,
			color: 'var(--bold)',
			width: '100%',
			cursor: 'pointer',
			height: 48,
			padding: '14px 12px',
			textAlign: 'left',
			fontSize: '15px',
		}),
		placeholder: provided => ({
			...provided,
			fontSize: '',
			color: '#767993',
		}),
	},
};
export const reactSelectConfigurationDate = {
	isSearchable: true,
	isMulti: false,

	styles: {
		option: (provided, { isFocused, isSelected }) => ({
			...provided,
			color: isSelected || isFocused ? '#3164F7' : '#0A0D2C',
			background: 'white',
			width: '100%',
			cursor: 'pointer',
			opacity: isFocused ? '0.8' : '',
			padding: '8px',
			transition: '400ms',
			fontSize: '14px',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '14px',
			},
		}),
		container: provided => ({
			...provided,
			width: '100%',
		}),
		singleValue: provided => ({
			...provided,
			color: 'var(--bold)',
		}),
		control: (provided, state) => ({
			...provided,
			outline: 'none',
			borderRadius: 8,
			border: state?.isSelected || state?.isFocused ? '1px solid #3164F7' : '1px solid #E3E3E3',
			cursor: 'pointer',
			color: '#0A0D2C',
			height: 44,
			margin: '0',
			paddingLeft: '16px',
			paddingRight: '16px',
			boxShadow: '',
			transition: '400ms',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '12px',
				paddingLeft: '12px',
				paddingRight: '12px',
			},
			':hover': {
				border: '1px solid #3164F7',
			},
		}),
		menuPortal: provided => ({
			...provided,
			zIndex: 999999999,
		}),
		menu: provided => ({
			...provided,
			margin: 0,
			width: '100%',
			padding: 16,
			border: '0!important',
			borderRadius: 8,
			boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.08)',
			background: 'white',
			overflow: 'hidden',
			top: '50px',
		}),
		menuList: provided => ({
			...provided,
			padding: 0,
			height: 'auto',
			zIndex: 11111111,
			'::-webkit-scrollbar': {
				width: '4px',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-track': {
				background: '#F5F5F5',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#767993',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb:hover': {
				// background: '#555',
			},
		}),
		indicatorSeparator: provided => ({
			...provided,
			display: 'none',
		}),
		indicatorsContainer: (provided, { isFocused }) => ({
			...provided,
			height: '100%',
			paddingRight: '0px',
			display: 'none',
		}),
		dropdownIndicator: (provided, { isFocused }) => ({
			...provided,
			color: 'var(--grey)',
			paddingRight: isFocused ? '8px' : '0px',
			paddingLeft: isFocused ? '0px' : '8px',
			transform: isFocused ? 'rotate(180deg)' : '',
			':hover': {
				color: 'var(--grey)',
			},
		}),
		valueContainer: provided => ({
			...provided,
			padding: '0',
			fontWeight: '500',
			alignContent: 'center',
			fontSize: '14px',
			'@media (max-width: 768px)': {
				...provided['@media (max-width: 768px)'],
				fontSize: '14px',
			},
		}),
		noOptionsMessage: provided => ({
			...provided,
			color: 'var(--bold)',
			width: '100%',
			cursor: 'pointer',
			height: 48,
			padding: '14px 12px',
			textAlign: 'left',
			fontSize: '15px',
		}),
		placeholder: provided => ({
			...provided,
			fontSize: '',
			color: '#767993',
		}),
	},
};

export const avatar_bg = ['#4B4D6C', '#3164F7', '#754ADA', '#FF843B', '#FF334C', '#50CD89'];

export const status_bg = [
	{
		id: 29,
		name: 'Жив',
		type_id: 9,
		bg_color: 'ts_green',
	},
	{
		id: 30,
		name: 'Умер от осложнений лечения',
		type_id: 9,
		bg_color: 'ts_red',
	},
	{
		id: 31,
		name: 'Умер от основного заболевания',
		type_id: 9,
		bg_color: 'ts_red',
	},
	{
		id: 32,
		name: 'Умер от других заболеваний',
		type_id: 9,
		bg_color: 'ts_red',
	},
	{
		id: 33,
		name: 'Выехал',
		type_id: 9,
		bg_color: 'ts_blue',
	},
	{
		id: 34,
		name: 'ts_orange',
		type_id: 9,
		bg_color: 'ts_grey',
	},
	{
		id: 35,
		name: 'Снят с учета в связи с истечением срока наблюдения',
		type_id: 9,
		bg_color: 'ts_grey',
	},
];
