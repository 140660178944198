import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useRole, userRoles } from '../../utils/roles';
import RSelect from './Select';
import SelectMulti from './SelectMulti';

const InputUi = props => {
	const { t } = useTranslation();
	const { hasRole } = useRole();
	const textContainerRef = useRef(null); // Reference for measuring overflow
	const [isOverflowing, setIsOverflowing] = useState(false);
	const [disabledddd, isDisabledddd] = useState(false);
	const [show, setShow] = useState(false);
	const location = useLocation();
	const { death_days } = useSelector(s => s);
	useEffect(() => {
		const isPatientsPage = location.pathname.startsWith('/patients');

		if (isPatientsPage && death_days === false) {
			isDisabledddd(true);
		}
	}, []);

	// Function to check if the content overflows
	const checkOverflow = () => {
		const element = textContainerRef.current;
		if (element) {
			// console.log(
			//   element.scrollWidth,
			//   element.clientWidth,
			//   props.value,
			//   getTooltipContent()?.length
			// );

			setIsOverflowing(getTooltipContent()?.length * 9 > element.clientWidth);
		}
	};

	useEffect(() => {
		checkOverflow(); // Check on mount and when value changes
	}, [props.value]);

	const getTooltipContent = () => {
		if (props?.type === 'select') return props?.value?.label || '-------';
		return props?.value || '-----';
	};

	return (
		<>
			<div
				// className="hover:bg-slate-400 hover:rounded-md"
				data-tooltip-id='input_tooltip'
				data-tooltip-content={isOverflowing ? getTooltipContent() : ''} // Only show tooltip if overflowing
				ref={textContainerRef}
			>
				<div className={`flex flex-col justify-between h-full gap-2 w-full p-0 border-none ${props?.className ?? ''}`}>
					{props?.label ? (
						<label htmlFor='' className='text-dark text-xs font-medium'>
							{props?.label} <span className='text-red'>{props?.required ? '*' : ''}</span>
						</label>
					) : null}

					{props?.type === 'select' ? (
						<>
							<RSelect
								{...props}
								isMulti={props?.isMulti}
								options={
									props?.options?.length
										? props?.has_empty_value
											? [
													{
														label: '—',
														value: null,
													},
													...props?.options,
											  ]
											: props?.options
										: [
												{
													label: t('common.loading'),
													value: null,
													disabled: true,
												},
										  ]
								}
								required={false}
								{...(disabledddd === true || hasRole(userRoles?.view) ? { isDisabled: true } : {})}
							/>
						</>
					) : props?.type === 'select2' ? (
						<>
							<SelectMulti
								{...props}
								options={
									props?.options?.length
										? props?.has_empty_value
											? [
													{
														label: '—',
														value: null,
													},
													...props?.options,
											  ]
											: props?.options
										: [
												{
													label: t('common.loading'),
													value: null,
													disabled: true,
												},
										  ]
								}
								required={false}
								{...(disabledddd === true || hasRole(userRoles?.view) ? { isDisabled: true } : {})}
							/>
						</>
					) : props?.type === 'textarea' ? (
						<textarea
							{...props}
							required={false}
							cols='30'
							rows='1'
							className={`py-3 border-[1px] rounded-lg border-greyMercury text-14 placeholder:text-blueWaterloo font-medium
            duration-300 hover:border-[1.34px]  disabled:bg-greyDisabled disabled:border-greyMercury
            ${props?.border_error ? props?.border_error : 'hover:border-blue'}`}
							placeholder={props?.placeholder}
							{...(disabledddd === true || hasRole(userRoles?.view) ? { disabled: true } : {})}
						/>
					) : props?.type === 'date' ? (
						<input
							{...props}
							required={false}
							placeholder={props?.placeholder}
							type='date'
							style={props?.value ? { color: '#000' } : {}}
							className={`h-44 py-3 border-[1px] rounded-lg border-greyMercury text-14 placeholder:!text-blueWaterloo font-medium
            duration-300 hover:border-[1.34px] disabled:bg-greyDisabled  disabled:border-greyMercury ${props?.border_error ? props?.border_error : 'hover:border-blue'}`}
							{...(disabledddd === true || hasRole(userRoles?.view) ? { disabled: true } : {})}
						/>
					) : props?.type === 'phone' ? (
						<InputMask
							{...props}
							className={`h-44 p-3 border-[1px] rounded-lg border-greyMercury text-14 placeholder:text-blueWaterloo font-medium
          duration-300 hover:border-[1.34px]  disabled:bg-greyDisabled disabled:border-greyMercury
          ${props?.border_error ? props?.border_error : 'hover:border-blue'}`}
							required={false}
							mask={'+998 (nn) nnn-nn-nn'}
							// disabled={is_disabled}
							placeholder={'+998 (99) 999-99-99'}
							maskChar='_'
							alwaysShowMask={false}
							formatChars={{
								n: '[0-9]',
								a: '[A-Za-z]',
								'*': '[A-Za-z0-9]',
							}}
							{...(disabledddd === true || hasRole(userRoles?.view) ? { disabled: true } : {})}
						/>
					) : props?.type === 'number' ? (
						<input
							{...props}
							required={false}
							placeholder={props?.placeholder}
							type='number'
							className={`h-44 py-3 border-[1px] rounded-lg border-greyMercury text-14 placeholder:text-blueWaterloo font-medium duration-300 hover:border-[1.34px] 
              disabled:bg-greyDisabled disabled:border-greyMercury
              ${props?.border_error ? props?.border_error : 'hover:border-blue'}`}
							{...(disabledddd === true || hasRole(userRoles?.view) ? { disabled: true } : {})}
						/>
					) : props?.type === 'password' ? (
						<div
							className={` ${props?.className} ${
								props?.border_error ? props?.border_error : 'hover:border-blue'
							} h-44 py-3  rounded-lg flex  justify-between items-center  disabled:bg-greyDisabled disabled:border-greyMercury  border-greyMercury border `}
						>
							<input
								className={` w-full  border-none ${props?.className} py-2  rounded-lg  text-14 placeholder:text-blueWaterloo font-medium duration-300 hover:border-[1.34px] 
			  
			  `}
								{...props}
								required={false}
								placeholder={props?.placeholder}
								type={show ? 'text' : 'password'}
								{...(disabledddd === true || (hasRole(userRoles?.view) && props?.disabled !== false) ? { disabled: true } : {})}
							/>
							<div
								className=' cursor-pointer px-2'
								onClick={() => {
									setShow(!show);
								}}
							>
								<div>{show ? <img src='/images/eye.png' alt='' className='h-[30px]' /> : <img src='/images/eye2.png' alt='' className='h-[30px]' />}</div>
							</div>
						</div>
					) : (
						<input
							{...props}
							required={false}
							placeholder={props?.placeholder}
							type='text'
							className={` ${
								props?.className
							} h-44 py-3 border-[1px] rounded-lg border-greyMercury text-14 placeholder:text-blueWaterloo font-medium duration-300 hover:border-[1.34px] 
              disabled:bg-greyDisabled disabled:border-greyMercury
              ${props?.border_error ? props?.border_error : 'hover:border-blue'}`}
							{...(disabledddd === true || (hasRole(userRoles?.view) && props?.disabled !== false) ? { disabled: true } : {})}
						/>
					)}
				</div>
			</div>

			{isOverflowing && <Tooltip id='input_tooltip' place='bottom' className='inline max-w-[500px] z-9999' />}
		</>
	);
};

export default InputUi;
