import React from "react";

const ArrowUp = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || "14"}
      height={props?.height || "8"}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7L6.29289 1.70711C6.62623 1.37377 6.79289 1.20711 7 1.20711C7.20711 1.20711 7.37377 1.37377 7.70711 1.70711L13 7"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowUp;
