import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { reactSelectConfiguration } from '../../utils/constants';

const customStyles = {
	control: provided => ({
		...provided,
		borderColor: 'red',
		padding: '10px',
		height: 'auto!important',
		overflow: 'auto',
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? 'lightblue' : 'red',
		color: 'black',
		padding: '1px',
	}),
	menu: provided => ({
		...provided,
		borderBottom: '1px solid blue',
		color: 'black',
		padding: '0px',
		backgroundColor: 'red',
	}),
	menuList: provided => ({
		...provided,
		padding: 0, // menuList ning paddingni yo'qotish
	}),
};

const RSelect = props => {
	const { t } = useTranslation();

	return (
		<div className='relative'>
			<Select
				isMulti={props?.isMulti}
				maxMenuHeight={200}
				placeholder={t('multi_select.select_an_option')}
				{...reactSelectConfiguration}
				{...props}
				className='p-0'
				components={{
					DropdownIndicator: () => null,
					// IndicatorSeparator: () => null,
				}}
				options={
					props?.options || [
						{ value: 'chocolate', label: 'Chocolate' },
						{ value: 'strawberry', label: 'Strawberry' },
						{ value: 'vanilla', label: 'Vanilla' },
						{ value: 'chocolate', label: 'Chocolate' },
						{ value: 'strawberry', label: 'Strawberry' },
						{ value: 'vanilla', label: 'Vanilla' },
						{ value: 'chocolate', label: 'Chocolate' },
						{ value: 'strawberry', label: 'Strawberry' },
						{ value: 'vanilla', label: 'Vanilla' },
						{ value: 'chocolate', label: 'Chocolate' },
						{ value: 'strawberry', label: 'Strawberry' },
						{ value: 'vanilla', label: 'Vanilla' },
						{ value: 'chocolate', label: 'Chocolate' },
						{ value: 'strawberry', label: 'Strawberry' },
						{ value: 'vanilla', label: 'Vanilla' },
					]
				}
				menuPlacement='auto'
				menuPosition='fixed'
			/>
		</div>
	);
};

export default RSelect;
