import React, { Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './i18n';
import { RoleProvider } from './roles';
const LoadingMain = lazy(() => import('../sections/utilsSections/LoadingMain'));
const Modal = lazy(() => import('../components/ui_components/Modal'));

const WebProvider = ({ children }) => {
	const dispatch = useDispatch();
	const { loading, ui_modal_show, ui_modal_body, roles } = useSelector(s => s);
	const closeModal = () => {
		dispatch({ type: 'SET_CLOSE_MADAL' });
	};
	return (
		<RoleProvider roles={roles}>
			{children}
			<Suspense>{loading && <LoadingMain />}</Suspense>
			<Suspense>
				<Modal is_open={ui_modal_show} onClose={closeModal} body={ui_modal_body} />
			</Suspense>
		</RoleProvider>
	);
};
export default WebProvider;
