import React from 'react';

const Th = props => {
	return (
		<th  style={props?.style} rowSpan={props?.rowSpan} colSpan={props?.colSpan} className={`min-w-[100px] font-medium px-4 py-2.5 border border-greyMercury ${props.className}`}>
			{props.children}
		</th>
	);
};

export default Th;
