import React from "react";

const Td = (props) => {
  return (
    <td
      className={`min-w-[100px] px-4 py-2.5 border border-greyMercury ${props.className}`}
      colSpan={props?.colSpan || null}
    >
      {props.children}
    </td>
  );
};

export default Td;
