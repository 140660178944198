import React from 'react';

const HamburgerMenu = props => {
	return (
		<svg width={props?.width || '18'} height={props?.height || '16'} viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M1 1L17 1' stroke={props?.stroke || '#141B34'} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M1 8L17 8' stroke={props?.stroke || '#141B34'} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M1 15L17 15' stroke={props?.stroke || '#141B34'} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
};

export default HamburgerMenu;
