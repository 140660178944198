import React from "react";

const EditIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width || "22"}
      height={props?.height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9459 2.17305C16.5332 1.58578 16.8268 1.29215 17.1521 1.15173C17.6208 0.949424 18.1521 0.949424 18.6208 1.15173C18.946 1.29215 19.2397 1.58578 19.8269 2.17305C20.4142 2.76032 20.7079 3.05395 20.8483 3.37925C21.0506 3.8479 21.0506 4.37924 20.8483 4.84789C20.7079 5.17319 20.4142 5.46682 19.8269 6.05409L14.8054 11.0757C13.5682 12.3129 12.9496 12.9315 12.1748 13.298C11.4 13.6645 10.5294 13.7504 8.78823 13.9222L8 14L8.07778 13.2118C8.24958 11.4706 8.33549 10.6 8.70201 9.8252C9.06854 9.05042 9.68713 8.43183 10.9243 7.19464L15.9459 2.17305Z"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M5 14H2.75C1.7835 14 1 14.7835 1 15.75C1 16.7165 1.7835 17.5 2.75 17.5H12.25C13.2165 17.5 14 18.2835 14 19.25C14 20.2165 13.2165 21 12.25 21H10"
        stroke={props?.stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
