import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RSelect from './Select';

const DateInput = ({ label = '', required = false, value = {}, onChange = () => {}, day_key = 'day', month_key = 'month', year_key = 'year', is_error = false }) => {
	const { t } = useTranslation();
	const [days, setDays] = useState([]);
	const year_list = Array.from({ length: 2024 - 1900 + 1 }, (v, k) => ({
		value: 2024 - k,
		label: 2024 - k,
	}));
	const yearOptions = [{ value: null, label: '—' }, ...year_list];

	const [disabledddd, isDisabledddd] = useState(false);
	const location = useLocation();
	const { death_days } = useSelector(s => s);

	useEffect(() => {
		const isPatientsPage = location.pathname.startsWith('/patients');

		if (isPatientsPage && death_days === false) {
			isDisabledddd(true);
		}
	}, []);

	const monthOptions = [
		{ value: null, label: '—' },
		{ value: 1, label: t('months.january') },
		{ value: 2, label: t('months.february') },
		{ value: 3, label: t('months.march') },
		{ value: 4, label: t('months.april') },
		{ value: 5, label: t('months.may') },
		{ value: 6, label: t('months.june') },
		{ value: 7, label: t('months.july') },
		{ value: 8, label: t('months.august') },
		{ value: 9, label: t('months.september') },
		{ value: 10, label: t('months.october') },
		{ value: 11, label: t('months.november') },
		{ value: 12, label: t('months.december') },
	];

	const handleChange = (name, value) => {
		onChange({ target: { name, value } });
	};

	const daysInMonth = (month, year) => {
		return new Date(year ? year : 2020, month ? month : 1, 0).getDate();
	};

	useEffect(() => {
		let day_list = Array.from(
			{
				length: daysInMonth(get(value, month_key, null), get(value, year_key, null)),
			},
			(v, k) => ({ value: k + 1, label: k + 1 })
		);
		setDays([{ value: null, label: '—' }, ...day_list]);
		if (get(value, day_key, 0) > daysInMonth(get(value, month_key, null), get(value, year_key, null))) {
			handleChange(day_key, daysInMonth(get(value, month_key, null), get(value, year_key, null)));
		}
	}, [get(value, month_key, null), get(value, year_key, null)]);

	return (
		<div>
			<div className='flex flex-col justify-between h-full gap-2 w-full p-0 border-none'>
				{label ? (
					<label htmlFor='' className='text-dark text-xs font-medium'>
						{label} <span className='text-red'>{required ? '*' : ''}</span>
					</label>
				) : null}
				<div>
					<div className='grid grid-cols-9 gap-1 w-full'>
						<div className='col-span-2'>
							<RSelect
								placeholder=''
								menuPlacement='auto'
								value={days.find(o => o.value === get(value, day_key, null))}
								onChange={e => handleChange(day_key, parseInt(e.value))}
								options={days}
								// {...reactSelectConfigurationDate}
								// isSearchable={false}

								{...(disabledddd === true ? { isDisabled: true } : {})}
							/>
						</div>
						<div className='col-span-4  2xl:col-span-3'>
							<RSelect
								placeholder=''
								menuPlacement='auto'
								value={monthOptions.find(o => o.value === get(value, month_key, null))}
								onChange={e => handleChange(month_key, parseInt(e.value))}
								options={monthOptions}
								// {...reactSelectConfigurationDate}
								// isSearchable={false}
								{...(disabledddd === true ? { isDisabled: true } : {})}
							/>
						</div>
						<div className='col-span-3 2xl:col-span-4'>
							<RSelect
								placeholder=''
								menuPlacement='auto'
								value={yearOptions.find(o => o.value === get(value, year_key, null))}
								onChange={e => handleChange(year_key, parseInt(e.value))}
								onInputChange={e => {
									if (e) handleChange(year_key, parseInt(e));
								}}
								options={yearOptions}
								// {...reactSelectConfigurationDate}
								{...(disabledddd === true ? { isDisabled: true } : {})}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DateInput;
