import React, { useState } from "react";
import { StarCircleIcon } from "../../assets/svg";

const CardRadio = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const options = [
    {
      id: "option1",
      full_name: "Алимов Азиз Рахматулаевич",
      address: "Республиканский Центр Онкологии и Радиологии",
      status: "суперадмин",
      status_star: true,
    },
    {
      id: "option2",
      full_name: "Алимов Азиз Рахматулаевич",
      address: "Ташкентский Областной Онкологический Диспансер",
      status: "админ",
      status_star: false,
    },
  ];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="flex flex-col w-full justify-center items-center">
      {options.map((option) => (
        <label
          key={option.id}
          className={`border flex w-full gap-3 rounded-lg p-4 my-4 cursor-pointer shadow-[0px 10px 30px rgba(0, 0, 0, 0.02)] ${
            selectedOption === option.id
              ? "border-blue bg-blue-100"
              : "border-greylight"
          }`}
        >
          <input
            type="radio"
            name="options"
            value={option.id}
            checked={selectedOption === option.id}
            onChange={handleOptionChange}
            // className="hidden"
          />
          <div className="-mt-2 flex flex-col gap-1.5">
            <p className="flex gap-2 items-center flex-wrap">
              <span className="text-sm font-medium">{option.full_name}</span>
              <span className="bg-purplelight py-1 px-1.5 rounded-xl flex flex-wrap items-center gap-1 text-xs font-normal">
                {option.status_star ? (
                  <StarCircleIcon stroke="#5D0D56" width="20px" height="20px" />
                ) : null}
                <span>{option.status}</span>
              </span>
            </p>
            <p className="font-normal text-xs text-blueWaterloo ">
              {option.address}
            </p>
          </div>
        </label>
      ))}
    </div>
  );
};

export default CardRadio;
