import React from 'react';
import { avatar_bg } from '../../utils/constants';

const RoundFirstLetter = props => {
	function getAvatarColor() {
		const firstLetter = props?.letter.toUpperCase();

		const charCode = firstLetter.charCodeAt(0);
		const colorIndex = charCode % 6;

		const avatarColor = avatar_bg[colorIndex];

		return avatarColor;
	}
	const avatarColor = getAvatarColor();
	const avatarStyle = {
		backgroundColor: avatarColor,
	};
	return (
		<div style={avatarStyle} className={`w-[32px] min-h-8 h-[32px] rounded-full flex items-center justify-center border ${props?.className}`}>
			<span className='text-white'>{props?.letter}</span>
		</div>
	);
};

export default RoundFirstLetter;
