import React from 'react';

const ButtonMain = props => {
	return (
		<button
			{...props}
			className={
				props?.className +
				` flex items-center justify-center gap-2 text-sm font-semibold px-4 py-2.5 rounded-lg duration-150 cursor-pointer ${
					props?.type_color === 't_blue'
						? 'border border-blue bg-blue text-white hover:opacity-75'
						: props?.type_color === 't_red'
						? ''
						: props?.type_color === 't_redlight'
						? 'border border-redlight2 bg-redlight text-red hover:opacity-75'
						: props?.type_color === 't_bluelight'
						? 'border border-blueHawkes bg-blueZumthor text-blue hover:opacity-75'
						: props?.type_color === 't_grey'
						? 'border border-greyMercury bg-greylight text-dark hover:opacity-75'
						: props?.type_color === 't_transparent'
						? 'bg-transparent text-dark hover:opacity-75 hover:text-red'
						: ''
				}`
			}
		>
			{props?.lefticon}
			<span className='!select-none'>{props?.text}</span>
			{props?.righticon}
		</button>
	);
};

export default ButtonMain;
