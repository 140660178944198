import React from "react";
import { reactSelectConfigurationMulti } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import ReactSelect, { components } from "react-select";

const Option = (props) => {
  const { t } = useTranslation();
  return (
    <components.Option {...props}>
      <div className="flex items-center justify-between">
        <label>{props.label}</label>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="form-checkbox h-5 w-5 text-[#3164F7] border border-[#3164F7] rounded-md focus:ring-[#3164F7]"
        />
      </div>
    </components.Option>
  );
};

const ValueContainer = ({ children, getValue, ...props }) => {
  let maxToShow = 2;
  var length = getValue().length;
  let displayChips = React.Children.toArray(children).slice(0, maxToShow);
  let shouldBadgeShow = length > maxToShow;
  let displayLength = length - maxToShow;

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && displayChips}
      {shouldBadgeShow && (
        <div className="root bg-[#F5F5F5] p-[2.5px_6px] text-[#0A0D2C] rounded-[4px]">
          {`+${displayLength}`}
        </div>
      )}
    </components.ValueContainer>
  );
};
const SelectMulti = (props) => {
  const { t } = useTranslation();

  return (
    <ReactSelect
      isMulti
      {...props}
      maxMenuHeight={200}
      // hideSelectedOptions={false}
      // closeMenuOnSelect={false}
      styles={{
        control: (base) => ({
          ...base,
          borderColor: "#E3E3E3",
          color: "#767993",
          fontSize: "14px",
          fontWeight: "500",
          borderRadius: "8px",
          height: "44px",
          overflow: "auto",
          boxShadow: "none",
        }),
        menu: (base) => ({
          ...base,
          zIndex: 9999,
        }),
        option: (base) => ({
          ...base,
          padding: "8px 16px",
          fontSize: "14px",
          fontWeight: "400",
          color: "#0A0D2C",
          backgroundColor: "transparent",
          ":hover": {
            backgroundColor: "#F5F5F5",
          },
        }),
        dropdownIndicator: (base) => ({
          ...base,
          color: "#0A0D2C",
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: "none",
        }),
        valueContainer: (base) => ({
          ...base,
          display: "flex",
        }),
        multiValueLabel: (base) => ({
          ...base,
          maxWidth: "60px",
          fontSize: "14px",
          fontWeight: "400",
          color: "#0A0D2C",
          padding: 0,
        }),
        multiValue: (base) => ({
          ...base,
          maxWidth: "80px",
          backgroundColor: "#F5F5F5",
          borderRadius: "4px",
          padding: "4px 6px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
        }),
        multiValueRemove: (base) => ({
          ...base,
          height: "max-content",
          padding: "0",
          color: "#0A0D2C",
        }),
      }}
      placeholder={t("multi_select.select_an_option")}
      options={
        props?.options || [
          { value: "chocolate", label: "Chocolate" },
          { value: "strawberry", label: "Strawberry" },
          { value: "vanilla", label: "Vanilla" },
          // ... other options
        ]
      }
      menuPlacement="auto"
      menuPosition="fixed"
      components={{
        Option,
        ValueContainer,
      }}
    />
  );
};

export default SelectMulti;
