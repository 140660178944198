// import _ from "lodash";

import { get } from 'lodash';

const INITIAL_STATE = {
	loading: false,
	user: {},
	patientData: {},
	patientValidationErrors: [],
	patientLoading: false,
	treatmentData: {},
	ui_modal_show: false,
	ui_modal_body: null,
	list_guides: [],
	death_days: true,
	roles: [],
};

const reducers = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action?.payload,
			};
		case 'SET_USER':
			return {
				...state,
				user: action?.payload,
			};
		case 'SET_ROLE':
			return {
				...state,
				roles: action?.payload,
			};
		case 'DEATH_DAYS':
			return {
				...state,
				death_days: action?.payload,
			};
		case 'SET_LIST_GUIDES':
			return {
				...state,
				list_guides: action?.payload,
			};
		case 'SET_PATIENT_DATA':
			let new_patient = { ...action?.payload, validation_errors: undefined };
			return {
				...state,
				patientData: new_patient,
				patientValidationErrors: get(action, 'payload.validation_errors', []),
			};
		case 'SET_PATIENT_VALID_ERROR':
			return {
				...state,
				patientValidationErrors: action.payload,
				patientData: {
					...state.patientData,
					status: action.payload.length ? 1 : 2,
				},
			};
		case 'SET_PATIENT_LOADING':
			return {
				...state,
				patientLoading: action?.payload,
			};
		case 'SET_TREATMENT_DATA':
			return {
				...state,
				treatmentData: action?.payload,
			};
		case 'SET_MODAL':
			return {
				...state,
				ui_modal_show: true,
				ui_modal_body: action?.payload,
			};
		case 'SET_CLOSE_MADAL':
			return {
				...state,
				ui_modal_show: false,
				ui_modal_body: null,
			};
		default:
			return state;
	}
};
export default reducers;
