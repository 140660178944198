import React from "react";
import { ArrowRight } from "../../assets/svg";

const ArrowTitle = (props) => {
  return (
    <div
      {...props}
      className={`w-full flex items-center gap-1 ${props?.cursor} text-purple text-xs font-semibold uppercase mb-4 ${props?.className}`}
    >
      {/* <ArrowRight stroke="#5D0D56" width="11px" height="11px" /> */}
      <span className="mt-[0.4px]">{props?.children}</span>
    </div>
  );
};

export default ArrowTitle;
