import React, { createContext, useContext, useMemo } from "react";

const RoleContext = createContext();
export const RoleProvider = ({ roles, children }) => {
  const roleMap = useMemo(
    () => new Map(roles.map((role) => [role.id, role])),
    [roles]
  );
  return (
    <RoleContext.Provider value={roleMap}>{children}</RoleContext.Provider>
  );
};

export const useRole = () => {
  const roleMap = useContext(RoleContext);
  const hasRole = (roleId) => roleMap.has(roleId);

  const getRoleName = (roleId) => roleMap.get(roleId)?.name || "Unknown Role";

  return { hasRole, getRoleName };
};
export const userRoles = {
  superuser: 1,
  admin: 2,
  reg: 3,
  view: 4,
};
