import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toastUi from "../../sections/utilsSections/toastUi";
import { AUTH_URL, SSO_CLIENT_ID, SSO_URL } from "../../utils/api";
import Axios from "../../utils/httpClient";
import { getToken, setToken } from "../../utils/tokenStorge";
import { ButtonMain, Card8Radius, InputUi } from "../ui_components";

const Login = () => {
  const [tab, setTab] = useState(0);
  const [obj, setObj] = useState({});
  const [err, setErr] = useState({});
  const token = getToken();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("translations", { keyPrefix: "login" });
  const navigate = useNavigate();
  const handleTab = (i) => setTab(i);

  //Auth functions
  function base64urlencode(str) {
    const bytes = new Uint8Array(str);
    const regularArray = Array.from(bytes);
    // Convert the input string to base64url format
    return btoa(String.fromCharCode.apply(null, regularArray))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }

  async function sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    const crypto = await window.crypto.subtle.digest("SHA-256", data);

    return crypto;
  }

  async function kceChallengeFromVerifier(v) {
    const hashed = await sha256(v);
    return base64urlencode(hashed);
  }

  function generateRandomString() {
    const array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => `0${dec.toString(16)}`.substr(-2)).join(
      ""
    );
  }

  async function redirectToSSO() {
    localStorage.removeItem("pkce_state");
    localStorage.removeItem("pkce_code_verifier");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("expires_date");
    const state = generateRandomString();
    localStorage.setItem("pkce_state", state);
    const codeVerifier = generateRandomString();
    localStorage.setItem("pkce_code_verifier", codeVerifier);
    const codeChallenge = await kceChallengeFromVerifier(codeVerifier);
    const originURL = window.location.origin;
    const url =
      `${SSO_URL}oauth/authorize?response_type=code` +
      `&client_id=` +
      SSO_CLIENT_ID +
      `&state=${encodeURIComponent(state)}&redirect_uri=${encodeURIComponent(
        // "https://mis-temp.ssv.uz/auth/callback"
        `${originURL}/auth/callback`
      )}&code_challenge=${encodeURIComponent(
        codeChallenge
      )}&code_challenge_method=S256`;

    window.location.href = url;
  }

  const handleChange = (e) => {
    setObj({ ...obj, [e.target.name]: e.target.value });
    setErr({ ...err, [e.target.name]: false });
  };

  const getUser = () => {
    Axios(AUTH_URL, token)
      .post(`api/v1/user`)
      .then((r) => {
        dispatch({ type: "SET_USER", payload: r?.data?.data ?? {} });
        dispatch({ type: "SET_ROLE", payload: r?.data?.data?.roles ?? {} });
      })
      .catch((e) => {})
      .finally(() => {});
  };
  const LoginSubmit = (e) => {
    e.preventDefault();
    let t = true,
      err = { username: false, password: false };
    if (!obj?.username) {
      t = false;
      err = { ...err, username: true };
    }
    if (!obj?.password) {
      t = false;
      err = { ...err, password: true };
    }

    if (t) {
      setLoading(true);
      Axios(AUTH_URL)
        .post("api/v1/login", obj)
        .then((res) => {
          if (res?.data?.access_token) {
            setToken(res?.data?.access_token);
            localStorage.setItem("refresh_token", res?.data?.refresh_token);
            localStorage.setItem("expires_in", res?.data?.expires_in);
            localStorage.setItem("expires_date", res?.data?.expires_date);
            getUser();
            navigate("/patients");
          }
        })
        .catch((err) => {
          toastUi.error(err?.response?.data?.error);
          setObj({
            username: "",
            password: "",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setErr(err);
    }
  };
  return (
    <div className="relative flex justify-center items-center w-full">
      {/* bg image */}
      {/* <botton>Login</botton> */}
      <img
        src="/images/linear_bg.png"
        className="w-full h-full absolute top-0 left-0"
        alt=""
      />

      {/* body */}
      <div
        className={`relative z-10  mt-[100px] mb-[50px]  flex justify-center flex-col items-center`}
      >
        {/* <form className=""> */}
        <Card8Radius
          className={`sm:w-[593px] ${
            tab === 0 ? "w-[calc(100%_-_30px)]" : "w-full"
          } min-h-32 bg-white border-none shadow-shadowRgb02 mb-[50px]`}
        >
          <div className="flex flex-col items-center justify-center w-full my-5 relative z-10 ">
            {/* {tab === 0 ? (
							<>
								<img src='/images/login_img1.png' className='w-[150px] h-[150px]' alt='' />
								<p className='text-2xl font-semibold text-dark mb-2 mt-7'>{t('authorization')}</p>
								<p className='text-base font-normal text-dark text-center sm:px-12 px-2'>{t('auth_title')}</p>
								<div className='flex flex-wrap gap-2 mt-8'>
									<ButtonMain onClick={redirectToSSO} text={t('auth_button_text')} type='submit' type_color='t_blue' lefticon={<LockedIcon stroke='#fff' />} />
								</div>
							</>
						) : (
							<>
								<img src='/images/login_img2.png' className='w-[150px] h-[150px]' alt='' />
								<p className='text-2xl font-semibold text-dark mb-2 mt-7'>{t('login.select_rol')}</p>
								<CardRadio />
								<div className='flex flex-wrap gap-2 mt-2 w-full items-center'>
									<ButtonMain className='!w-full' text={t('continue')} type='submit' type_color='t_blue' />
								</div>
							</>
						)} */}
            <form
              onSubmit={LoginSubmit}
              className="flex flex-col gap-3 w-full  justify-center"
            >
              <div className="flex justify-center">
                <img
                  src="/images/login_img1.png"
                  className="w-[150px] h-[150px] "
                  alt=""
                />
              </div>
              <p className="text-2xl font-semibold text-dark mb-2 mt-7 text-center">
                {t("authorization")}
              </p>
              <p className="text-base font-normal text-dark text-center sm:px-12 px-2">
                {t("auth_title")}
              </p>
              <div className="flex justify-center w-full">
                <div className="w-[80%] flex flex-col gap-3 mt-3">
                  <InputUi
                    disabled={false}
                    border_error={err?.username ? " border  border-red" : ""}
                    name="username"
                    value={obj?.username}
                    onChange={handleChange}
                    placeholder={t("username")}
                  />
                  <InputUi
                    disabled={false}
                    border_error={err?.password ? " border  border-red" : ""}
                    name="password"
                    value={obj?.password}
                    onChange={handleChange}
                    type="password"
                    placeholder={t("password")}
                  />
                  <ButtonMain
                    text={loading ? "...loading" : t("login")}
                    type_color="t_blue"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </div>
        </Card8Radius>
        {/* </form> */}

        {/* footer title */}
        <div className="flex justify-center items-center">
          <div className="flex gap-1 items-center">
            <p className="text-sm font-normal text-blueWaterloo">
              {t("powered_by")}
            </p>
            <img
              src="/images/uzinfocom.png"
              className="w-[90px] min-h-[11px]"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
