import axios from "axios";
import toastUi from "../sections/utilsSections/toastUi";
import { API_URL } from "./api";
import { removeToken } from "./tokenStorge";

// axios.interceptors.request.use(
//   (config) => config,
//   (e) => Promise.reject(e)
// );
const Axios = (service_url = null, access_token = "") => {
  const token = access_token ? access_token : localStorage.getItem("token");
  let defaultOptions = {
    baseURL: service_url ? service_url : API_URL,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
          lang: localStorage.getItem("i18nextLng") || "uz",
        }
      : {
          lang: localStorage.getItem("i18nextLng") || "uz",
        },
    // params: { lan: localStorage.getItem('i18nextLng') || 'uz', }
  };

  const instance = axios.create();
  instance.interceptors.response.use(
    (response) => response,
    (e) => {
      if (typeof e?.toJSON === "function") {
        const error = e?.toJSON();
        if (error?.status === 401) {
          removeToken();
          if (window.location.pathname !== "/login") {
            window.location.href = "/login";
          }
        }
        if (error?.status === 403) {
          window.location.href = "/directories";
        }
        if (error?.message === "Network Error") {
          toastUi.warning(
            localStorage.getItem("i18nextLng") === "uz"
              ? "Internetingiz uzildi!"
              : "Нет подключения к Интернету!"
          );
        }
      }
      if (e?.response?.status === 422) {
        toastUi.error(`422: ${e?.response?.data?.message ?? ""}`);
      }
      return Promise.reject(e);
    }
  );
  return {
    get: (url, options = {}) =>
      instance.get(url, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) =>
      instance.post(url, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) =>
      instance.put(url, data, { ...defaultOptions, ...options }),
    delete: (url, options = {}) =>
      instance.delete(url, { ...defaultOptions, ...options }),
  };
};
export default Axios;
